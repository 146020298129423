import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { Button, Icon, openNotification, Text } from 'shared/components/ui';
import { useLazyGetCandidateProfileLinkQuery, useGetCurrentUserQuery } from 'services';
import { ProgAICandidate } from 'shared/generated-models';
import { useLocalStorage } from 'shared/hooks';
import { ProgAIProfile, ProgAIResults } from 'shared/generated-models';
import { ProjectMenu } from 'features/projects';
import { ContactModal, ProfileVariant, useOpenContacts } from 'features/candidate';
// import { Analytics } from 'features/analytics';

import styles from './index.module.scss';

interface Props {
	data?: ProgAICandidate['profile'];
	onClose: () => void;
	variant: ProfileVariant;
	candidates: ProgAIProfile[];
	page: number;
	total?: number;
	setCandidate: (candidate: ProgAIResults | null) => void;
	onChange: (page: number) => void;
}

export const CandidateHeader: FC<Props> = ({
	data,
	onClose,
	variant = 'search',
	candidates,
	page,
	total,
	setCandidate,
	onChange
}: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [hasOpenedContactsModal, setHasOpenedContactsModal] = useLocalStorage('hasOpenedContactsModal', false);
	const { data: user } = useGetCurrentUserQuery();
	const [openContacts] = useOpenContacts(variant);

	const [getProfileLink] = useLazyGetCandidateProfileLinkQuery();

	const bonusSkills = data?.skills?.map(skill => skill.skill).join(',');

	const id = variant === 'search' ? data?.id : data?.prog_ai_id;

	const closeModal = () => {
		setIsModalOpen(false);
	};

	// const handleShare = () => {
	// 	// Analytics.trackProfileShare(id!);

	// 	getProfileLink(id!)
	// 		.unwrap()
	// 		.then(res => {
	// 			navigator.clipboard.writeText(res.link).then(() => {
	// 				openNotification({ text: 'Profile link copied to clipboard' });
	// 			});
	// 		});
	// };

	// const handleContacts = async () => {
	// 	if (hasOpenedContactsModal) {
	// 		if (user && user.credits < 1) {
	// 			openNotification({
	// 				text: 'Not enough credits'
	// 			});
	// 		} else {
	// 			try {
	// 				await openContacts(id!).unwrap();
	// 				// Analytics.trackGetContact(id!);
	// 			} catch (e) {
	// 				console.error(e);
	// 			}
	// 		}
	// 	} else {
	// 		setIsModalOpen(true);
	// 		setHasOpenedContactsModal(true);
	// 	}
	// };

	const idx = (() => {
		if (candidates) return candidates.findIndex(candidate => candidate.profile_id === id);
	})();
	const isFirstPage = page === 1;
	const isFirstCandidate = idx === 0;

	const isLastPage = (() => {
		if (!!total) return total <= (page + 1) * 20;
		if (candidates) return (page + 1) * 20 > page * 20 + candidates.length;
	})();
	const isLastCandidate = (() => {
		if (candidates) return idx === candidates.length - 1;
	})();

	const handlePrevCandidateClick = () => {
		if (isFirstCandidate) return;
		if (candidates && setCandidate && idx !== undefined) setCandidate({
			match_metadata: {
				weight: 1,
				match_score: '99'
			},
			profile: candidates[idx - 1]
		});
	};

	const handleNextCandidateClick = () => {
		if (isLastCandidate) return;
		if (candidates && setCandidate && idx !== undefined) setCandidate({
			match_metadata: {
				weight: 1,
				match_score: '99'
			},
			profile: candidates[idx + 1]
		});
	};

	const handlePrevPage = () => {
		onChange(page - 1);
		if (setCandidate) setCandidate(null);
	};

	const handleNextPage = () => {
		onChange(page + 1);
		if (setCandidate) setCandidate(null);
	};

	return (
		<>
			<header className={styles.header}>
				<Button onClick={onClose} type="ghost" className={cn(styles.button, styles.back)}>
					<Icon icon="chevron" size={20} />
				</Button>
				{data && (
					<>
						<div className={styles.headerSection}>
							<Button
								onClick={isFirstCandidate ? handlePrevPage : handlePrevCandidateClick}
								type="ghost"
								className={cn(styles.button, {
									[styles.buttonDisabled]: isFirstCandidate && isFirstPage
								})}
								prefix={
									<Icon icon="chevron" size={20} className={cn(styles.icon, styles.iconPrevious)} />
								}>
								<Text variant="inter/14/regular" color="grey">
									Previous
								</Text>
							</Button>
							<Button
								onClick={isLastCandidate ? handleNextPage : handleNextCandidateClick}
								type="ghost"
								className={cn(styles.button, {
									[styles.buttonDisabled]: isLastCandidate && isLastPage
								})}
								prefix={<Icon icon="chevron" size={20} className={cn(styles.icon)} />}>
								<Text variant="inter/14/regular" color="grey">
									Next
								</Text>
							</Button>
							{/* <Link
								to={`/search/results?page=0&bonus_skills[]=${bonusSkills}&size=100`}
								target="_blank"
								rel="noopener,nofollow,noreferrer">
								<Button
									type="ghost"
									className={styles.button}
									prefix={<Icon icon="search-candidate" className={styles.icon} />}>
									<Text variant="inter/13/regular" color="grey">
										Find similar
									</Text>
								</Button>
							</Link>
							<Button
								onClick={handleShare}
								type="ghost"
								className={styles.button}
								prefix={<Icon icon="external" size={20} className={styles.icon} />}>
								<Text variant="inter/13/regular" color="grey">
									Copy link
								</Text>
							</Button> */}
						</div>
						{/* {variant === 'search' && (
							<div className={cn(styles.headerSection, styles.headerSectionRight)}>
								{!data.emails && (
									<Button
										className={styles.buttonContact}
										onClick={handleContacts}
										prefix={<Icon icon="mail" className={styles.icon} />}>
										Get contact
										<Icon icon="credits" className={styles.iconCredits} size={14} />
									</Button>
								)}
								<ProjectMenu candidates={{ count: 1, ids: [id!] }}>
									<Button
										type="secondary"
										className={styles.buttonProject}
										suffix={<Icon icon="arrow-down" />}>
										Add to project
									</Button>
								</ProjectMenu>
							</div>
						)} */}
					</>
				)}
			</header>
			{isModalOpen && <ContactModal onClose={closeModal} id={id!} variant={variant} />}
		</>
	);
};
