import { useEffect } from 'react';

import { setHeaderTitle, useAppDispatch } from 'store';
import { ResultsList, SideSearchForm } from 'features/search/components';

import styles from './index.module.scss';

export const Results = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setHeaderTitle('Search'));
	}, [dispatch]);

	return (
		<section className={styles.container}>
			<SideSearchForm />
			<ResultsList />
		</section>
	);
};
