import { ReactNode, useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { ConfigProvider, Form, Space, Tooltip } from 'antd';
import cn from 'classnames';

import {
	Button,
	Checkbox,
	TextButton,
	Text,
	FormikInput,
	FormikInputNumber,
	SideFormikSelectInput,
	SideFormikSelectInputWithNegative,
	FormikSlider,
	FormikCheckboxGroup,
	FormikRadioGroup,
	Radio,
	Icon
} from 'shared/components/ui';
import { SearchParams, LeadsPerCompany, FundingDate, Headcount } from 'shared/generated-models';
import { SearchFormParams } from 'features/search/models';
import { SearchAutocomplete, SearchAutocompleteWithNegative, SearchModal } from 'features/search/components';
import {
	useGetCompaniesQuery,
	useGetCountriesQuery,
	useGetIndustriesQuery,
	useGetJobTitlesQuery,
	useGetLocationsQuery,
	useGetRegionsQuery,
	useGetSubregionsQuery,
	useGetBuyersIntentQuery,
	useGetTechnographicsQuery,
	useGetCompanyCountriesQuery,
	useGetCompanyIndustriesQuery,
	useGetCampusesQuery,
	useGetLanguagesQuery
} from 'services';
import { useFilters, useGetCandidates, useSearch } from 'features/search/hooks';
import { formIsEmpty } from 'shared/utils';
import { AttachFile } from 'features/search/components';
import { useIsAdvancedFilters, useFileContext } from 'features/auth';

import styles from './index.module.scss';

interface TooltipAdvancedProps {
	children: ReactNode;
	disabled: boolean;
}

const TooltipAdvancedFilter = ({ children, disabled }: TooltipAdvancedProps) => {
	return (
		<>
			{disabled ? (
				<Tooltip
					arrow={false}
					title={
						<div className={styles.tooltip}>
							<Text color="dark_green">
								Please upgrade to Basic or Business package to get access to additional filters
							</Text>
						</div>
					}
					mouseEnterDelay={1}
					placement="right"
					overlayClassName={styles.labelTooltip}>
					<div>{children}</div>
				</Tooltip>
			) : (
				<>{children}</>
			)}
		</>
	);
};

export const SideSearchForm = () => {
	const [openModal, setOpenModal] = useState(false);
	const [isOpen, setIsOpen] = useState(true);
	const [showAdvancedFilters, setShowAdvancedFilters] = useState(true);
	const filters = useFilters();
	const search = useSearch();
	const { contextValue, setContextValue } = useFileContext();
	const advancedFiltersEnabled = useIsAdvancedFilters();

	const { isFetching } = useGetCandidates();

	const formik = useFormik<SearchParams>({
		initialValues: filters,
		validateOnMount: true,
		enableReinitialize: true,
		validate: values => {
			const errors: Partial<Record<keyof SearchFormParams, string>> = {};

			if (values['total_funding_end'] && values['total_funding_start']) {
				if (values['total_funding_end'] < values['total_funding_start']) {
					errors.total_funding_start = 'Range is incorrect';
					errors.total_funding_end = 'Range is incorrect';
				}
			}
			return errors;
		},
		onSubmit: values => {
			search({ ...values, page: 1 }, { refresh: true });
			setOpenModal(false);
		}
	});

	const formHasValue = !formIsEmpty({ ...formik.values, size: undefined, page: undefined }) || !!contextValue;

	const openForm = () => {
		setOpenModal(true);
	};

	const closeForm = () => {
		setOpenModal(false);
	};

	const handleOpen = () => {
		setIsOpen(isOpen => !isOpen);
	};

	const clearFilters = () => {
		formik.setValues({});
		setContextValue(null);
	};

	const isLookup = (values: any) => {
		// if ('first_name' in values && values['first_name']) return true;
		// if ('last_name' in values && values['last_name']) return true;
		// if ('companies' in values && values['companies'].length > 0) return true;
		return false;
	};
	const disabledFields = isLookup(formik.values);

	useEffect(() => {
		if (disabledFields) {
			const newValues: any = {};
			// if ('first_name' in formik.values && formik.values['first_name'])
			// 	newValues['first_name'] = formik.values['first_name'];
			// if ('last_name' in formik.values && formik.values['last_name'])
			// 	newValues['last_name'] = formik.values['last_name'];
			if ('linkedin_url' in formik.values && formik.values['linkedin_url'])
				newValues['linkedin_url'] = formik.values['linkedin_url'];
			if ('companies' in formik.values && formik.values['companies'] && formik.values['companies'].length > 0)
				newValues['companies'] = formik.values['companies'];
			formik.setValues(newValues);
		}
	}, [disabledFields]);

	useEffect(() => {
		return () => {
			if (!window.location.pathname.includes('/companies_search/results')) setContextValue(null);
		};
	}, []);

	const removeFundingDateField = () => {
		const newValues = { ...formik.values };
		delete newValues['last_funding_date'];
		formik.setValues(newValues);
	};

	const removeCompanyDomainRankField = () => {
		const newValues = { ...formik.values };
		delete newValues['company_domain_rank'];
		formik.setValues(newValues);
	};

	const removeCompanyFundedYearField = () => {
		const newValues = { ...formik.values };
		delete newValues['company_founded_year'];
		formik.setValues(newValues);
	};

	const handleAdvancedFiltersClick = () => {
		setShowAdvancedFilters(!showAdvancedFilters);
	};

	return (
		<ConfigProvider
			theme={{
				components: {
					Tooltip: {
						paddingSM: 0,
						paddingXS: 0,
						borderRadius: 2,
						colorBgDefault: '#f8f8f8'
					}
				}
			}}>
			<Button className={styles.openButton} onClick={handleOpen}>
				<Icon icon="filter" />
			</Button>
			<section className={cn(styles.formContainer, { [styles.formContainerOpen]: isOpen })}>
				<div className={styles.formTitle}>
					<Text component="span" color="grey" variant="inter/14/medium">
						Filters
					</Text>
					{formHasValue && <TextButton onClick={clearFilters}>Clear all</TextButton>}
				</div>
				<FormikProvider value={formik}>
					<Form layout="vertical">
						<div className={styles.formHeight}>
							<SearchAutocompleteWithNegative
								name="job_titles"
								className={styles.formField}
								useQuery={useGetJobTitlesQuery}
								onClick={openForm}
								label={
									<div className={styles.label}>
										<Text variant="inter/14/regular">Functional Area</Text>
										<Tooltip
											arrow={false}
											title={
												<div className={styles.tooltip}>
													<Text color="dark_green">
														Select functional area of the prospects within their company.
														We've a curated list of 13 areas/departments in total.
													</Text>
												</div>
											}
											placement="right"
											overlayClassName={styles.labelTooltip}>
											<Icon icon="info" className={styles.labelIcon} />
										</Tooltip>
									</div>
								}
								placeholder="Enter or select functional area"
								disabled={disabledFields}
							/>
							<div className={styles.formField}>
								<SideFormikSelectInputWithNegative
									name="title_keywords"
									label={
										<div className={styles.label}>
											<Text variant="inter/14/regular">Job Title keywords</Text>
											<Tooltip
												arrow={false}
												title={
													<div className={styles.tooltip}>
														<Text color="dark_green">
															Search target prospects by specific Job Titles, i.e "Big
															Data", "Compliance Officer", "Founder" etc. Muraena searches for keywords using OR operator. If you are looking for exact match, use quotes "". To exclude certain title, simply click on it.
														</Text>
													</div>
												}
												placement="right"
												overlayClassName={styles.labelTooltip}>
												<Icon icon="info" className={styles.labelIcon} />
											</Tooltip>
										</div>
									}
									placeholder="Enter keywords"
									onClick={openForm}
									disabled={disabledFields}
								/>
							</div>
							<SearchAutocompleteWithNegative
								name="industries"
								className={styles.formField}
								useQuery={useGetIndustriesQuery}
								onClick={openForm}
								label={
									<div className={styles.label}>
										<Text variant="inter/14/regular">Industries</Text>
										<Tooltip
											arrow={false}
											title={
												<div className={styles.tooltip}>
													<Text color="dark_green">
														Select industry of the prospect's company. We serve standard
														list of LinkedIn industries + some of the most popular modern
														tech industries, such as SaaS, Sales Automation, etc.
													</Text>
												</div>
											}
											placement="right"
											overlayClassName={styles.labelTooltip}>
											<Icon icon="info" className={styles.labelIcon} />
										</Tooltip>
									</div>
								}
								placeholder="Enter or select industries"
								disabled={disabledFields}
							/>
							<div className={styles.formField}>
								<SideFormikSelectInputWithNegative
									name="keywords"
									label={
										<div className={styles.label}>
											<Text variant="inter/14/regular">Company keywords</Text>
											<Tooltip
												arrow={false}
												title={
													<div className={styles.tooltip}>
														<Text color="dark_green">
															You can describe narrow/specific market segments that are not
													possible to find by Industry search - for
													example, "email marketing agency". Muraena searches for keywords using OR operator. If you're looking for exact match, use quotes "". To exclude certain title, simply click on it.
														</Text>
													</div>
												}
												placement="right"
												overlayClassName={styles.labelTooltip}>
												<Icon icon="info" className={styles.labelIcon} />
											</Tooltip>
										</div>
									}
									placeholder="Enter keywords"
									onClick={openForm}
									disabled={disabledFields}
								/>
							</div>
							{/* <div className={cn(styles.formField, styles.formItem)}>
								<FormikInput
									name="lookalike"
									label={
										<div className={styles.label}>
											<Text variant="inter/14/regular">Find similar companies</Text>
											<Tooltip
												arrow={false}
												title={
													<div className={styles.tooltip}>
														<Text color="dark_green">
															Submit one or many companies web domains, and Muraena will
															generate a list of look-alike companies, and will search for
															employees from those companies (working in the same
															industry, target market, etc.)
														</Text>
													</div>
												}
												placement="right"
												overlayClassName={styles.labelTooltip}>
												<Icon icon="info" className={styles.labelIcon} />
											</Tooltip>
										</div>
									}
									size="small"
									placeholder="Enter domains"
								/>
							</div> */}
							<div className={styles.formField}>
								<SideFormikSelectInputWithNegative
									name="lookalike"
									label={
										<div className={styles.label}>
											<Text variant="inter/14/regular">Find similar companies</Text>
											<Tooltip
												arrow={false}
												title={
													<div className={styles.tooltip}>
														<Text color="dark_green">
															Submit one or more companies' domains, and Muraena will generate a
										list of look-alike companies (the same industry, target market, location etc.)
														</Text>
													</div>
												}
												placement="right"
												overlayClassName={styles.labelTooltip}>
												<Icon icon="info" className={styles.labelIcon} />
											</Tooltip>
										</div>
									}
									placeholder="Enter domains"
									onClick={openForm}
								/>
							</div>
							<SearchAutocompleteWithNegative
								name="bombora_surge_list_topic"
								className={styles.formField}
								useQuery={useGetBuyersIntentQuery}
								onClick={openForm}
								label={
									<div className={styles.label}>
										<Text variant="inter/14/regular">Buyers Intent</Text>
										<Tooltip
											arrow={false}
											title={
												<div className={styles.tooltip}>
													<Text color="dark_green">
														Indicates a company's "likelihood to buy" based on their
														behavioral signals. F.e., if you selling email marketing
														services, select "Email Marketing" as a topic. This is very narrow and limiting filter.
													</Text>
												</div>
											}
											placement="right"
											overlayClassName={styles.labelTooltip}>
											<Icon icon="info" className={styles.labelIcon} />
										</Tooltip>
									</div>
								}
								placeholder="Enter or select buyers intent topics"
								disabled={disabledFields}
							/>
							<SearchAutocompleteWithNegative
								name="technography"
								className={styles.formField}
								useQuery={useGetTechnographicsQuery}
								onClick={openForm}
								label={
									<div className={styles.label}>
										<Text variant="inter/14/regular">Technographics</Text>
										<Tooltip
											arrow={false}
											title={
												<div className={styles.tooltip}>
													<Text color="dark_green">
														Company’s technographic data - software platforms and
														integrations they are using.
													</Text>
												</div>
											}
											placement="right"
											overlayClassName={styles.labelTooltip}>
											<Icon icon="info" className={styles.labelIcon} />
										</Tooltip>
									</div>
								}
								placeholder="Enter or select technologies"
								disabled={disabledFields}
							/>
							<SearchAutocomplete
								name="locations"
								className={styles.formField}
								useQuery={useGetLocationsQuery}
								onClick={openForm}
								label={
									<div className={styles.label}>
										<Text variant="inter/14/regular">City or State</Text>
										<Tooltip
											arrow={false}
											title={
												<div className={styles.tooltip}>
													<Text color="dark_green">
														City or State of the prospect. Please do not specify broader
														location-related filters (Country or Region), when City or State
														is specified.
													</Text>
												</div>
											}
											placement="right"
											overlayClassName={styles.labelTooltip}>
											<Icon icon="info" className={styles.labelIcon} />
										</Tooltip>
									</div>
								}
								placeholder="Enter city or state"
								disabled={disabledFields}
							/>
							<SearchAutocomplete
								name="countries"
								className={styles.formField}
								useQuery={useGetCountriesQuery}
								onClick={openForm}
								label={
									<div className={styles.label}>
										<Text variant="inter/14/regular">Country</Text>
										<Tooltip
											arrow={false}
											title={
												<div className={styles.tooltip}>
													<Text color="dark_green">
														Country of the prospect. Please do not specify any other
														location-related filters, when Country is specified.
													</Text>
												</div>
											}
											placement="right"
											overlayClassName={styles.labelTooltip}>
											<Icon icon="info" className={styles.labelIcon} />
										</Tooltip>
									</div>
								}
								placeholder="Enter country"
								disabled={disabledFields}
							/>
							<SearchAutocomplete
								name="subregions"
								className={styles.formField}
								useQuery={useGetSubregionsQuery}
								onClick={openForm}
								label={
									<div className={styles.label}>
										<Text variant="inter/14/regular">Subregion</Text>
										<Tooltip
											arrow={false}
											title={
												<div className={styles.tooltip}>
													<Text color="dark_green">
														Subregion of the prospect. Please do not specify any other
														location-related filters, when Subregion is specified.
													</Text>
												</div>
											}
											placement="right"
											overlayClassName={styles.labelTooltip}>
											<Icon icon="info" className={styles.labelIcon} />
										</Tooltip>
									</div>
								}
								placeholder="Enter subregion"
								disabled={disabledFields}
							/>
							<SearchAutocomplete
								name="regions"
								className={styles.formField}
								useQuery={useGetRegionsQuery}
								onClick={openForm}
								label={
									<div className={styles.label}>
										<Text variant="inter/14/regular">Region</Text>
										<Tooltip
											arrow={false}
											title={
												<div className={styles.tooltip}>
													<Text color="dark_green">
														Region of the prospect. Please do not specify any other
														location-related filters, when Region is specified.
													</Text>
												</div>
											}
											placement="right"
											overlayClassName={styles.labelTooltip}>
											<Icon icon="info" className={styles.labelIcon} />
										</Tooltip>
									</div>
								}
								placeholder="Enter region"
								disabled={disabledFields}
							/>

							<FormikCheckboxGroup
								label={<Text>Company Headcount</Text>}
								disabled={disabledFields}
								name="headcount"
								className={cn(styles.formField, styles.formFieldCheckbox)}>
								<Checkbox value={Headcount._1_10}>1-10</Checkbox>
								<Checkbox value={Headcount._11_50}>11-50</Checkbox>
								<Checkbox value={Headcount._51_200}>51-200</Checkbox>
								<Checkbox value={Headcount._201_500}>201-500</Checkbox>
								<Checkbox value={Headcount._501_1000}>501-1000</Checkbox>
								<Checkbox value={Headcount._1001_5000}>1001-5000</Checkbox>
								<Checkbox value={Headcount._5001_10000}>5001-10000</Checkbox>
								<Checkbox value={Headcount._10000_}>10000+</Checkbox>
							</FormikCheckboxGroup>
							<div className={cn(styles.formField, styles.formItem)}>
								<div style={{ marginBottom: '8px' }}>
									<Text variant="inter/14/regular">Total Funding Raised</Text>
								</div>
								<div className={cn(styles.row, styles.rowFunding)}>
									<FormikInputNumber
										prefix="$"
										name="total_funding_start"
										size="large"
										placeholder="From"
									/>
									<FormikInputNumber
										prefix="$"
										name="total_funding_end"
										size="large"
										placeholder="To"
									/>
								</div>
							</div>
							<FormikRadioGroup
								name="last_funding_date"
								disabled={disabledFields}
								label={
									<div className={styles.labelFunding}>
										<div className={styles.label}>
											<Text variant="inter/14/regular">Last Funding date</Text>
											<Tooltip
												arrow={false}
												title={
													<div className={styles.tooltip}>
														<Text color="dark_green">
															If company raised any funding, here you can select a date of
															their last
														</Text>
													</div>
												}
												placement="right"
												overlayClassName={styles.labelTooltip}>
												<Icon icon="info" className={styles.labelIcon} />
											</Tooltip>
										</div>
										{'last_funding_date' in formik.values && (
											<TextButton onClick={removeFundingDateField} className={styles.clear}>
												Clear
											</TextButton>
										)}
									</div>
								}
								className={cn(styles.formField, styles.formFieldCheckbox)}>
								<Space direction="vertical">
									<Radio key={FundingDate.past_30_days} value={FundingDate.past_30_days}>
										Past 30 days
									</Radio>
									<Radio key={FundingDate.past_90_days} value={FundingDate.past_90_days}>
										Past 90 days
									</Radio>
									<Radio key={FundingDate.past_year} value={FundingDate.past_year}>
										Past Year
									</Radio>
									<Radio key={FundingDate.past_2_years} value={FundingDate.past_2_years}>
										Past 2 Years
									</Radio>
								</Space>
							</FormikRadioGroup>
							{/* <FormikCheckboxGroup
								label={<Text>Leads per company</Text>}
								name="leads_per_company"
								className={cn(styles.formField, styles.formFieldCheckbox)}>
								<Checkbox value={LeadsPerCompany._5_}>5+</Checkbox>
								<Checkbox value={LeadsPerCompany._3_5}>3-5</Checkbox>
								<Checkbox value={LeadsPerCompany._1_3}>1-3</Checkbox>
							</FormikCheckboxGroup> */}
							{/* <div className={cn(styles.formField, styles.formItem)}>
								<FormikInput
									name="first_name"
									label="First Name"
									size="small"
									placeholder="Enter first name"
								/>
							</div>
							<div className={cn(styles.formField, styles.formItem)}>
								<FormikInput
									name="last_name"
									label="Last Name"
									size="small"
									placeholder="Enter last name"
								/>
							</div> */}
							<div className={cn(styles.formField, styles.formItem)}>
								<FormikInput
									name="linkedin_url"
									label="Person Linkedin URL"
									size="small"
									placeholder="Enter linkedin url"
								/>
							</div>
							<SearchAutocompleteWithNegative
								name="companies"
								className={styles.formField}
								useQuery={useGetCompaniesQuery}
								onClick={openForm}
								label="Company"
								placeholder="Enter or select Companies"
							/>
							<div
								className={cn(styles.advanced, { [styles.advancedOpen]: !showAdvancedFilters })}
								onClick={handleAdvancedFiltersClick}>
								<Icon
									icon="arrow-down"
									className={cn({ [styles.advancedIcon]: !showAdvancedFilters })}
								/>
								<Text variant="inter/15/medium">Advanced Filters</Text>
							</div>
							{showAdvancedFilters && (
								<>
									<div className={styles.formField}>
										<TooltipAdvancedFilter
											children={
												<SideFormikSelectInputWithNegative
													name="summary_keywords"
													label={
														<div className={styles.label}>
															<Text variant="inter/14/regular">
																Person Summary keywords
															</Text>
														</div>
													}
													placeholder="Enter keywords"
													onClick={openForm}
													disabled={!advancedFiltersEnabled}
												/>
											}
											disabled={!advancedFiltersEnabled}
										/>
									</div>
									<TooltipAdvancedFilter
										children={
											<SearchAutocompleteWithNegative
												name="campuses"
												className={styles.formField}
												useQuery={useGetCampusesQuery}
												onClick={openForm}
												label={
													<div className={styles.label}>
														<Text variant="inter/14/regular">Person Education</Text>
													</div>
												}
												placeholder="Enter or select schools, universities"
												disabled={!advancedFiltersEnabled}
											/>
										}
										disabled={!advancedFiltersEnabled}
									/>
									<TooltipAdvancedFilter
										children={
											<SearchAutocompleteWithNegative
												name="languages"
												className={styles.formField}
												useQuery={useGetLanguagesQuery}
												onClick={openForm}
												label={
													<div className={styles.label}>
														<Text variant="inter/14/regular">Person Languages</Text>
													</div>
												}
												placeholder="Enter or select languages"
												disabled={!advancedFiltersEnabled}
											/>
										}
										disabled={!advancedFiltersEnabled}
									/>
									<div className={styles.formField}>
										<TooltipAdvancedFilter
											children={
												<SideFormikSelectInput
													name="person_email_domains"
													label={
														<div className={styles.label}>
															<Text variant="inter/14/regular">Person Email Domain</Text>
														</div>
													}
													placeholder="Enter domains"
													onClick={openForm}
													disabled={!advancedFiltersEnabled}
												/>
											}
											disabled={!advancedFiltersEnabled}
										/>
									</div>
									<div className={styles.formField}>
										<div className={styles.attach}>
											<AttachFile disabled={!advancedFiltersEnabled} />
										</div>
										<TooltipAdvancedFilter
											children={
												<SideFormikSelectInputWithNegative
													name="company_domains"
													label={
														<div className={styles.label}>
															<Text variant="inter/14/regular">Company Domain</Text>
														</div>
													}
													placeholder="Enter domains"
													onClick={openForm}
													disabled={!advancedFiltersEnabled}
												/>
											}
											disabled={!advancedFiltersEnabled}
										/>
									</div>
									<TooltipAdvancedFilter
										children={
											<SearchAutocomplete
												name="company_countries"
												className={styles.formField}
												useQuery={useGetCompanyCountriesQuery}
												onClick={openForm}
												label={
													<div className={styles.label}>
														<Text variant="inter/14/regular">Company Country</Text>
													</div>
												}
												placeholder="Enter country"
												disabled={!advancedFiltersEnabled}
											/>
										}
										disabled={!advancedFiltersEnabled}
									/>

									{/* <SearchAutocompleteWithNegative
										name="company_industries"
										className={styles.formField}
										useQuery={useGetCompanyIndustriesQuery}
										onClick={openForm}
										label={
											<div className={styles.label}>
												<Text variant="inter/14/regular">Company Industry</Text>
											</div>
										}
										placeholder="Enter Industry"
										disabled={disabledFields}
									/> */}
									<div className={cn(styles.formField, styles.formItem, styles.sliderWrapper)}>
										<div className={styles.sliderRow} style={{ marginBottom: '8px' }}>
											<Text variant="inter/14/regular">Company Domain Rank</Text>
											{'company_domain_rank' in formik.values && (
												<TextButton
													onClick={removeCompanyDomainRankField}
													className={styles.clear}>
													Clear
												</TextButton>
											)}
										</div>
										<div className={styles.slider}>
											<TooltipAdvancedFilter
												children={
													<FormikSlider
														name="company_domain_rank"
														min={0}
														max={100}
														disabled={!advancedFiltersEnabled}
													/>
												}
												disabled={!advancedFiltersEnabled}
											/>
										</div>
									</div>
									<div className={cn(styles.formField, styles.formItem, styles.sliderWrapper)}>
										<div className={styles.sliderRow} style={{ marginBottom: '8px' }}>
											<Text variant="inter/14/regular">Company Founded Year</Text>
											{'company_founded_year' in formik.values && (
												<TextButton
													onClick={removeCompanyFundedYearField}
													className={styles.clear}>
													Clear
												</TextButton>
											)}
										</div>
										<div className={styles.slider}>
											<TooltipAdvancedFilter
												children={
													<FormikSlider
														name="company_founded_year"
														min={1980}
														max={2024}
														disabled={!advancedFiltersEnabled}
													/>
												}
												disabled={!advancedFiltersEnabled}
											/>
										</div>
									</div>
								</>
							)}
						</div>
						<div className={styles.formButtons}>
							<Button
								type="ghost"
								prefix={<Icon icon="filter" className={styles.formButtonsFilter} />}
								onClick={openForm}>
								All filters
							</Button>
							<Button
								type="secondary"
								onClick={formik.handleSubmit}
								loading={isFetching}
								disabled={!formHasValue}>
								{isFetching ? 'Searching' : 'Get my leads'}
							</Button>
						</div>
					</Form>
					{openModal && <SearchModal onClose={closeForm} type="people" />}
				</FormikProvider>
			</section>
		</ConfigProvider>
	);
};
