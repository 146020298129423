import { FC } from 'react';
import cn from 'classnames';

import { Icon, Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	page: number;
	onChange: (page: number) => void;
	total?: number;
	pageSize?: number;
	onPageCount?: number;
}

function formatNumberWithCommas(number: number) {
	if (number > 300000) return '300,000+';
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const Pagination: FC<Props> = ({ page, onChange, pageSize = 50, total, onPageCount }: Props) => {
	const handlePrevPage = () => {
		onChange(page - 1);
	};

	const handleNextPage = () => {
		onChange(page + 1);
	};

	return (
		<div className={styles.pagination}>
			{!!total && (
				<Text variant="inter/14/semi">
					{/* {page * pageSize + 1}-{Math.min((page + 1) * pageSize, total)} */}
					{(page - 1) * pageSize + 1}-{Math.min(page * pageSize, total)}{' '}
					<span className={styles.grey}>out of {formatNumberWithCommas(total)}</span>
				</Text>
			)}
			{!total && onPageCount && (
				<Text variant="inter/14/semi">
					{/* {page * pageSize + 1}-{Math.min((page + 1) * pageSize, page * pageSize + onPageCount)} */}
					{(page - 1) * pageSize + 1}-{Math.min(page * pageSize, (page - 1) * pageSize + onPageCount)}
				</Text>
			)}
			<div className={styles.paginationButtons}>
				<button
					disabled={page === 1}
					onClick={handlePrevPage}
					className={cn(styles.paginationButton, styles.paginationPrevious)}>
					<Icon icon="chevron" />
				</button>
				{!!total && (
					<button
						disabled={total <= page * pageSize}
						onClick={handleNextPage}
						className={cn(styles.paginationButton, styles.paginationNext)}>
						<Icon icon="chevron" />
					</button>
				)}
				{!total && onPageCount && (
					<button
						disabled={page * pageSize > (page - 1) * pageSize + onPageCount}
						onClick={handleNextPage}
						className={cn(styles.paginationButton, styles.paginationNext)}>
						<Icon icon="chevron" />
					</button>
				)}
			</div>
		</div>
	);
};
