import React, { FC, useEffect } from 'react';
import { Modal } from 'antd';

import { CandidateHeader, CandidateProfile } from 'features/candidate/components';
import { ProgAIProfile, ProgAIResults } from 'shared/generated-models';
import { useGetCandidateProfileQuery } from 'services';
// import { Analytics } from 'features/analytics';
import { useAppDispatch } from 'store';
import { setOpenCandidateModal } from 'store';

import styles from './index.module.scss';

interface Props {
	candidate: ProgAIResults;
	onClose: () => void;
	candidates: ProgAIProfile[];
	page: number;
	setCandidate: (candidate: ProgAIResults | null) => void;
	onChange: (page: number) => void;
	total?: number;
}

export const CandidateModal: FC<Props> = ({
	candidate,
	candidates,
	onClose,
	page,
	setCandidate,
	onChange,
	total
}: Props) => {
	const { data } = useGetCandidateProfileQuery(candidate.profile.profile_id);

	const dispatch = useAppDispatch();

	useEffect(() => {
		// Analytics.trackProfileOpening(candidate.profile.id);
		dispatch(setOpenCandidateModal(true));

		return () => {
			dispatch(setOpenCandidateModal(false));
		};
	}, []);

	return (
		<Modal
			open={true}
			destroyOnClose={true}
			onCancel={onClose}
			footer={null}
			closable={false}
			className={styles.modal}
			transitionName="">
			<CandidateHeader
				variant="search"
				data={data}
				onClose={onClose}
				candidates={candidates}
				page={page}
				setCandidate={setCandidate}
				onChange={onChange}
				total={total}
			/>
			<CandidateProfile variant="search" candidate={candidate} data={data} />
		</Modal>
	);
};
