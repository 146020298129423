import { memo, useState } from 'react';

import { SearchHistory, SearchParams, SearchCompany } from 'shared/generated-models';
import { Icon, Text } from 'shared/components/ui';
import { Td, Tr } from 'features/table';
import { formatDate, formatTime, MONTH_DAY_FORMAT } from 'shared/utils';
import { useSearch, useCompanySearch } from 'features/search/hooks';
import { normalizeHeadcount } from 'features/search/utils';

import styles from './index.module.scss';

interface Props {
	searchItem: SearchHistory;
}

const formatNumberWithCommas = (number: number) => {
	if (number > 300000) return '300,000+';
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const formatSearchFilters = (values: SearchParams | SearchCompany) => {
	const filters = { ...values };
	if ('headcount' in filters) {
		filters['headcount'] = normalizeHeadcount(filters['headcount']);
	}

	let formattedFilters = '';
	if ('job_titles' in filters) {
		formattedFilters += `<span class="${styles.span}">Functional Area</span>: ${filters['job_titles']?.join(
			', '
		)}; `;
	}
	if ('negative_job_titles' in filters) {
		formattedFilters += `<span class="${styles.span}">Negative Functional Area</span>: ${filters[
			'negative_job_titles'
		]?.join(', ')}; `;
	}
	if ('title_keywords' in filters) {
		formattedFilters += `<span class="${styles.span}">Job Title Keywords</span>: ${filters['title_keywords']?.join(
			', '
		)}; `;
	}
	if ('negative_title_keywords' in filters) {
		formattedFilters += `<span class="${styles.span}">Negative Job Title Keywords</span>: ${filters[
			'negative_title_keywords'
		]?.join(', ')}; `;
	}
	if ('industries' in filters) {
		formattedFilters += `<span class="${styles.span}">Industry</span>: ${filters['industries']?.join(', ')}; `;
	}
	if ('negative_industries' in filters) {
		formattedFilters += `<span class="${styles.span}">Negative Industry</span>: ${filters[
			'negative_industries'
		]?.join(', ')}; `;
	}
	if ('keywords' in filters) {
		formattedFilters += `<span class="${styles.span}">Company Keywords</span>: ${filters['keywords']?.join(
			', '
		)}; `;
	}
	if ('negative_keywords' in filters) {
		formattedFilters += `<span class="${styles.span}">Negative Company Keywords</span>: ${filters[
			'negative_keywords'
		]?.join(', ')}; `;
	}
	if ('lookalike' in filters) {
		formattedFilters += `<span class="${styles.span}">Similar Companies</span>: ${filters['lookalike']}; `;
	}
	if ('bombora_surge_list_topic' in filters) {
		formattedFilters += `<span class="${styles.span}">Buyers Intent</span>: ${filters[
			'bombora_surge_list_topic'
		]?.join(', ')}; `;
	}
	if ('negative_bombora_surge_list_topic' in filters) {
		formattedFilters += `<span class="${styles.span}">Negative Buyers Intent</span>: ${filters[
			'negative_bombora_surge_list_topic'
		]?.join(', ')}; `;
	}
	if ('technography' in filters) {
		formattedFilters += `<span class="${styles.span}">Technographics</span>: ${filters['technography']?.join(
			', '
		)}; `;
	}
	if ('negative_technography' in filters) {
		formattedFilters += `<span class="${styles.span}">Negative Technographics</span>: ${filters[
			'negative_technography'
		]?.join(', ')}; `;
	}
	if ('locations' in filters) {
		formattedFilters += `<span class="${styles.span}">City or State</span>: ${filters['locations']?.join(', ')}; `;
	}
	if ('subregions' in filters) {
		formattedFilters += `<span class="${styles.span}">Subregion</span>: ${filters['subregions']?.join(', ')}; `;
	}
	if ('regions' in filters) {
		formattedFilters += `<span class="${styles.span}">Region</span>: ${filters['regions']?.join(', ')}; `;
	}
	if ('countries' in filters) {
		formattedFilters += `<span class="${styles.span}">Country</span>: ${filters['countries']?.join(', ')}; `;
	}
	if ('headcount' in filters) {
		formattedFilters += `<span class="${styles.span}">Headcount</span>: ${filters['headcount']?.join(', ')}; `;
	}
	if ('last_funding_date' in filters) {
		formattedFilters += `<span class="${styles.span}">Last Funding Date</span>: ${filters['last_funding_date']}; `;
	}
	// if ('first_name' in filters) {
	//     formattedFilters += `<span class="${styles.span}">First Name</span>: ${filters['first_name']}; `;
	// }
	// if ('last_name' in filters) {
	//     formattedFilters += `<span class="${styles.span}">Last Name</span>: ${filters['last_name']}; `;
	// }
	if ('linkedin_url' in filters) {
		formattedFilters += `<span class="${styles.span}">Linkedin URL</span>: ${filters['linkedin_url']}; `;
	}
	if ('companies' in filters) {
		formattedFilters += `<span class="${styles.span}">Company</span>: ${filters['companies']?.join(', ')}; `;
	}
	if ('negative_companies' in filters) {
		formattedFilters += `<span class="${styles.span}">Negative Company</span>: ${filters[
			'negative_companies'
		]?.join(', ')}; `;
	}
	if ('total_funding_amount' in filters) {
		formattedFilters += `<span class="${styles.span}">Total Funding Amount</span>: ${filters[
			'total_funding_amount'
			// @ts-ignore
		]?.join(', ')}; `;
	}
	if ('summary_keywords' in filters) {
		formattedFilters += `<span class="${styles.span}">Person Summary keywords</span>: ${filters[
			'summary_keywords'
		]?.join(', ')}; `;
	}
	if ('negative_summary_keywords' in filters) {
		formattedFilters += `<span class="${styles.span}">Negative Person Summary keywords</span>: ${filters[
			'summary_keywords'
		]?.join(', ')}; `;
	}
	if ('person_email_domains' in filters) {
		formattedFilters += `<span class="${styles.span}">Person Email Domain</span>: ${filters[
			'person_email_domains'
		]?.join(', ')}; `;
	}
	if ('company_domains' in filters) {
		formattedFilters += `<span class="${styles.span}">Company Domain</span>: ${filters['company_domains']?.join(
			', '
		)}; `;
	}
	if ('negative_company_domains' in filters) {
		formattedFilters += `<span class="${styles.span}">Negative Company Domain</span>: ${filters[
			'negative_company_domains'
		]?.join(', ')}; `;
	}
	if ('company_cities' in filters) {
		// @ts-ignore
		formattedFilters += `<span class="${styles.span}">Company City</span>: ${filters['company_cities']?.join(
			', '
		)}; `;
	}
	if ('company_countries' in filters) {
		formattedFilters += `<span class="${styles.span}">Company Country</span>: ${filters['company_countries']?.join(
			', '
		)}; `;
	}
	if ('company_industries' in filters) {
		formattedFilters += `<span class="${styles.span}">Company Industry</span>: ${filters[
			'company_industries'
		]?.join(', ')}; `;
	}
	if ('negative_company_industries' in filters) {
		formattedFilters += `<span class="${styles.span}">Negative Company Industry</span>: ${filters[
			'negative_company_industries'
		]?.join(', ')}; `;
	}
	if ('company_domain_rank' in filters) {
		formattedFilters += `<span class="${styles.span}">Company Domain Rank</span>: ${filters[
			'company_domain_rank'
		]?.join(', ')}; `;
	}
	if ('company_founded_year' in filters) {
		formattedFilters += `<span class="${styles.span}">Company Founded Year</span>: ${filters[
			'company_founded_year'
		]?.join(', ')}; `;
	}
	if ('campuses' in filters) {
		formattedFilters += `<span class="${styles.span}">Person Education</span>: ${filters['campuses']?.join(
			', '
		)}; `;
	}
	if ('negative_campuses' in filters) {
		formattedFilters += `<span class="${styles.span}">Negative Person Education</span>: ${filters[
			'negative_campuses'
		]?.join(', ')}; `;
	}
	if ('languages' in filters) {
		formattedFilters += `<span class="${styles.span}">Person Languages</span>: ${filters['languages']?.join(
			', '
		)}; `;
	}
	if ('negative_languages' in filters) {
		formattedFilters += `<span class="${styles.span}">Negative Person Languages</span>: ${filters[
			'negative_languages'
		]?.join(', ')}; `;
	}

	return formattedFilters;
};

export const SearchRow = memo(({ searchItem }: Props) => {
	const search = useSearch();
	const companySearch = useCompanySearch();

	const handleSearch = () => {
		const values = { ...searchItem.filters };
		if ('headcount' in values) {
			values['headcount'] = normalizeHeadcount(values['headcount']);
		}
		if (searchItem.content_type === 'people') search(values, null, true);
		else companySearch(values, null, true);
	};

	const getUnit = () => {
		if (searchItem.content_type === 'people') {
			return searchItem.leads_number === 1 ? 'lead' : 'leads';
		}
		return searchItem.leads_number === 1 ? 'company' : 'companies';
	};

	return (
		<>
			<Tr className={styles.profile}>
				<div className={styles.border}></div>
				<div className={styles.wrapper}>
					<Td className={styles.cell} width="144px">
						<Text className={styles.title} variant="inter/14/regular" component="p" color="grey">
							{formatDate(searchItem.created_at, MONTH_DAY_FORMAT)}, {formatTime(searchItem.created_at)}
						</Text>
					</Td>
					<Td className={styles.cell} width="500px">
						<Text className={styles.title} variant="inter/14/regular">
							<span dangerouslySetInnerHTML={{ __html: formatSearchFilters(searchItem.filters) }} />
						</Text>
					</Td>
					<Td className={styles.cell} width="164px">
						<Text className={styles.title} variant="inter/14/regular" component="p">
							{formatNumberWithCommas(searchItem.leads_number)} {getUnit()}
						</Text>
					</Td>
					<Td className={styles.cell} width="180px">
						<div className={styles.search} onClick={handleSearch}>
							<Text className={styles.title} variant="inter/14/regular" component="p">
								<span style={{ textDecoration: 'underline' }}>Search</span>
							</Text>
							<Icon icon="external" size={20} />
						</div>
					</Td>
				</div>
			</Tr>
		</>
	);
}, arePropsEqual);

function arePropsEqual(oldProps: Props, newProps: Props) {
	return oldProps.searchItem === newProps.searchItem;
}
