import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Icon, Text } from 'shared/components/ui';
import { useLazyGetResendVerificationLinkQuery } from 'services';

import styles from './index.module.scss';

export const FinishRegistration = () => {
	const [verify, setVerify] = useState<boolean>(false);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const email = searchParams.get('email');

	const [resendVerificationLink] = useLazyGetResendVerificationLinkQuery();

	const handleResendVerificationLink = async () => {
		if (!email) return;
		await resendVerificationLink({ email: encodeURIComponent(email) });
		setVerify(true);
	};

	const handleUseDifferentEmail = () => {
		navigate('/login');
	};

	return (
		<section className={styles.container}>
			<main className={styles.login}>
				<div className={styles.wrapper}>
					{!verify && (
						<>
							<div className={styles.body}>
								<Icon icon="check-mail" size={88} />
								<Text variant="inter/32/semi">Finish your registration!</Text>
								<Text variant="inter/16/medium" color="another_grey" className={styles.bodySubheader}>
									You have not verified your email <span>{email}</span> yet
								</Text>
							</div>
							<Button
								className={styles.button}
								type="primary"
								block
								onClick={handleResendVerificationLink}>
								Send verification link
							</Button>
							<span onClick={handleUseDifferentEmail}>
								<Text variant="inter/15/medium" className={styles.textButton}>
									Use different email
								</Text>
							</span>
						</>
					)}
					{verify && (
						<>
							<Icon icon="check-mail" />
							<Text variant="inter/32/semi" className={styles.verifyHeader}>
								Verify your email
							</Text>
							<div className={styles.verifyBody}>
								<Text variant="inter/16/medium" color="another_grey" className={styles.verifyBodyHeader}>
									We’ve sent an email to <span>{email}</span>
								</Text>
								<Text variant="inter/16/regular">
									Please check your email and click the link provided to create a password.
								</Text>
								<Text variant="inter/16/regular">
									If you don’t see our email, please check your spam.
								</Text>
							</div>

							<span onClick={handleResendVerificationLink}>
								<Text variant="inter/15/medium" className={styles.textButton}>
									Send again
								</Text>
							</span>
						</>
					)}
				</div>
			</main>
		</section>
	);
};
