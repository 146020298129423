import { useGetSearchCompaniesQuery, usePrefetch } from 'services';
import { SEARCH_LOADING_SIZE } from 'features/search/models';
import { CompanySearchParams } from 'shared/generated-models';
import { useFileContext } from 'features/auth';
import { useFilters } from './use-filters';

const PAGE_SIZE = 50;
const VIRTUAL_PAGES = SEARCH_LOADING_SIZE / PAGE_SIZE;

export const useGetCompanies = () => {
	const { contextValue, setContextValue } = useFileContext();
	const filters = useFilters();
	const prefetchPage = usePrefetch('getSearchCompanies');

	const modifiedFilters = { ...filters };

	if (!!contextValue) {
		// @ts-ignore
		if ('company_domains' in modifiedFilters && modifiedFilters['company_domains'].length > 0) {
			// @ts-ignore
			const updatedDomains = Array.from(new Set([...modifiedFilters['company_domains'], ...contextValue.domains]));
			modifiedFilters['company_domains'] = updatedDomains;
		} else {
			// @ts-ignore
			modifiedFilters['company_domains'] =  Array.from(new Set(contextValue.domains));
		}
	}

	if ('headcount' in modifiedFilters) {
		const prevHeadcount = modifiedFilters['headcount'];
		const newHeadcount: any = [];
		// @ts-ignore
		prevHeadcount.forEach(item => {
			if (item === '10000+') {
				return newHeadcount.push([10000, null]);
			}
			return newHeadcount.push(item.split('-').map((str: string) => parseInt(str)));
		});
		modifiedFilters['headcount'] = newHeadcount;
	}

	if ('total_funding_start' in modifiedFilters || 'total_funding_end' in modifiedFilters) {
		// @ts-ignore
		const start = modifiedFilters['total_funding_start'];
		// @ts-ignore
		const end = modifiedFilters['total_funding_end'];
		// const [start, end] = modifiedFilters['total_funding_amount'];
		// @ts-ignore
		modifiedFilters['total_funding_amount'] = [start ? parseInt(start) : null, end ? parseInt(end) : null];
	}
	if ('total_funding_start' in modifiedFilters) delete modifiedFilters['total_funding_start'];
	if ('total_funding_end' in modifiedFilters) delete modifiedFilters['total_funding_end'];

	if ('cities' in modifiedFilters) {
		if ('countries' in modifiedFilters) delete modifiedFilters['countries'];
		if ('subregions' in modifiedFilters) delete modifiedFilters['subregions'];
		if ('regions' in modifiedFilters) delete modifiedFilters['regions'];
	}
	if ('countries' in modifiedFilters) {
		if ('subregions' in modifiedFilters) delete modifiedFilters['subregions'];
		if ('regions' in modifiedFilters) delete modifiedFilters['regions'];
	}
	if ('subregions' in modifiedFilters) {
		if ('regions' in modifiedFilters) delete modifiedFilters['regions'];
	}
	if ('company_domain_rank' in modifiedFilters) {
		const range: any = modifiedFilters['company_domain_rank'];
		modifiedFilters['company_domain_rank'] = [parseInt(range[0]), parseInt(range[1])]
	}
	if ('company_founded_year' in modifiedFilters) {
		const range: any = modifiedFilters['company_founded_year'];
		modifiedFilters['company_founded_year'] = [parseInt(range[0]), parseInt(range[1])]
	}

	const normalizedPageNumber = Math.floor((PAGE_SIZE * (filters.page - 1)) / SEARCH_LOADING_SIZE) + 1;

	const aiSearch = (filters as CompanySearchParams).id;

	const { data, isFetching, isError, refetch } = useGetSearchCompaniesQuery({
		...modifiedFilters,
		id: undefined,
		ai_search: aiSearch ? +aiSearch : undefined,
		page: normalizedPageNumber
	});

	if (filters.page % VIRTUAL_PAGES === 0) {
		prefetchPage({ ...modifiedFilters, page: normalizedPageNumber + 1 });
	}

	const page = filters.page - 1 || 0;

	const pageNumberOnCurrentSegment = page % (SEARCH_LOADING_SIZE / PAGE_SIZE);

	const pagedResults =
		data?.results.slice(PAGE_SIZE * pageNumberOnCurrentSegment, PAGE_SIZE * (pageNumberOnCurrentSegment + 1)) || [];

	const pagedData = { ...data, results: pagedResults };

	const totalLoaded = data
		? Math.min(
				Math.ceil((PAGE_SIZE * filters.page + 1) / SEARCH_LOADING_SIZE) * SEARCH_LOADING_SIZE,
				Math.floor((PAGE_SIZE * filters.page + 1) / SEARCH_LOADING_SIZE) * SEARCH_LOADING_SIZE +
					data.results.length
		  )
		: 0;

	return { data: pagedData, rawData: data, isFetching, isError, refetch, totalLoaded };
};
