import { useLocation, useNavigate } from 'react-router-dom';

import { Icon, Text } from 'shared/components/ui';
import { useAppSelector } from 'store/redux-hooks';
import { setSearchType, useAppDispatch } from 'store';
import qs from 'query-string';

import cn from 'classnames';

import styles from './index.module.scss';

const PEOPLE_SPECIFIC_FILTERS = [
	'job_titles',
	'title_keywords',
	'locations',
	'countries',
	'subregions',
	'regions',
	'linkedin_url',
	'summary_keywords',
	'campuses',
	'languages',
	'person_email_domains'
];
const COMPANY_SPECIFIC_FILTERS = ['company_cities', 'linkedin_url'];

export const Tabs = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const searchType = useAppSelector(state => state.search);
	const dispatch = useAppDispatch();

	const changeTab = () => {
		const params = qs.parse(location.search, {
			arrayFormat: 'bracket-separator',
			arrayFormatSeparator: ','
		});

		if (searchType === 'people') {
			dispatch(setSearchType('companies'));
			if (location.pathname === '/search/results') {
				PEOPLE_SPECIFIC_FILTERS.forEach(filter => {
					if (filter in params) delete params[filter];
				});

				const url = qs.stringify(params, {
					arrayFormat: 'bracket-separator',
					arrayFormatSeparator: ','
				});
				navigate(`/companies_search/results${`?${url}`}`);
			}
		} else {
			dispatch(setSearchType('people'));
			if (location.pathname === '/companies_search/results') {
				COMPANY_SPECIFIC_FILTERS.forEach(filter => {
					if (filter in params) delete params[filter];
				});

				const url = qs.stringify(params, {
					arrayFormat: 'bracket-separator',
					arrayFormatSeparator: ','
				});
				navigate(`/search/results${`?${url}`}`);
			}
		}
	};

	return (
		<div className={cn(styles.search, { [styles.searchResults]: location.pathname.includes('results') })}>
			<div
				className={cn(styles.searchTab, {
					[styles.searchTabActive]: searchType === 'people',
					[styles.searchTabPeople]: searchType === 'people'
				})}
				onClick={changeTab}>
				<Icon icon="people" size={16} />
				<Text variant={searchType === 'people' ? 'grotesk/16/medium' : 'grotesk/16/regular'} component="h2">
					People
				</Text>
			</div>
			<div
				className={cn(styles.searchTab, {
					[styles.searchTabActive]: searchType === 'companies',
					[styles.searchTabCompanies]: searchType === 'companies'
				})}
				onClick={changeTab}>
				<Icon icon="companies" size={16} />
				<Text variant={searchType === 'companies' ? 'grotesk/16/medium' : 'grotesk/16/regular'} component="h2">
					Companies
				</Text>
			</div>
		</div>
	);
};
