import { createSlice } from '@reduxjs/toolkit';

const initialState = "people";

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setSearchType: (state, { payload }) => {
            return payload;
        },
    }
});

const { reducer } = searchSlice;
export const { setSearchType } = searchSlice.actions;

export const searchReducer = reducer;
