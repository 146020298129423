import { createApi } from '@reduxjs/toolkit/query/react';

import { interceptor } from 'services/interceptor';
import {
	CompanySearchParams,
	SearchParams,
	SearchSkills,
	ProgAISearchResult,
	PaginatedCompanyList,
	PaginatedProgAIProfileList,
	ProgAIResults
} from 'shared/generated-models';

export const searchService = createApi({
	reducerPath: 'searchService',
	baseQuery: interceptor,
	tagTypes: ['CompanySearch', 'Search'],
	endpoints: builder => ({
		getCandidates: builder.query<PaginatedProgAIProfileList, SearchParams>({
			query: params => ({
				url: `/search/${params.page && params.page > 0 ? `?page=${params.page}` : ''}`,
				method: 'POST',
				body: params,
				invalidatesTags: ['Search']
			})
		}),
		getSearchCompanies: builder.query<PaginatedCompanyList, CompanySearchParams>({
			query: params => ({
				url: `/companies_search/${params.page && params.page > 0 ? `?page=${params.page}` : ''}`,
				method: 'POST',
				body: params,
				invalidatesTags: ['CompanySearch']
			})
		}),
		uploadText: builder.mutation<SearchSkills, { text: string }>({
			query: params => ({
				url: '/upload_text/',
				method: 'POST',
				body: params
			})
		}),
		parseText: builder.mutation<SearchSkills, { input_text: string }>({
			query: params => ({
				url: '/text_parsing/',
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded' // Set the content type to HTML form
				},
				body: new URLSearchParams({ input_text: params.input_text }) // Convert data to HTML form format
			})
		}),
		uploadFile: builder.mutation<SearchSkills, Blob>({
			query: file => {
				const data = new FormData();
				data.append('file', file);

				return {
					url: '/upload_file/',
					method: 'POST',
					body: data
				};
			}
		})
	})
});

export const {
	useGetCandidatesQuery,
	useGetSearchCompaniesQuery,
	useUploadTextMutation,
	useUploadFileMutation,
	usePrefetch,
	useParseTextMutation
} = searchService;
