import { FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form } from 'antd';

import { Button, FormikInput, FormikInputPassword, Text } from 'shared/components/ui';
import { useAppDispatch } from 'store';
import { setCredentials } from 'store/credentials';
import { useSignInMutation } from 'services';
import { parseJwt } from 'shared/utils';
import { Analytics } from 'features/analytics';
import { Divider, GoogleSocialButton } from 'features/auth/components';
import { FetchErrorWithData, SignInEditForm } from 'shared/models';
import { signInFormValidationSchema } from 'features/registration';
import cn from 'classnames';
import { useLocalStorage } from 'shared/hooks';

import styles from './index.module.scss';

export const SignInForm = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [promo, setPromo] = useLocalStorage('promo', {});

	const accessToken = searchParams.get('access');
	const refreshToken = searchParams.get('refresh');
	const isNew = searchParams.get('is_new');

	const email = searchParams.get('email');

	const [signIn, { data, error }] = useSignInMutation();

	useEffect(() => {
		const promoParams = [
			'pipedrive_code',
			'hubspot_code',
			'appsumo_code',
			'coupon',
			'deal',
			'package',
		];

		let updatedParams = {};
		promoParams.forEach((param) => {
			if (promo[param] && !searchParams.has(param)) {
				//@ts-ignore
				updatedParams[param] = promo[param];
			}
		});

		if (Object.keys(updatedParams).length > 0) {
			Object.entries(updatedParams).forEach(([key, value]) => {
				searchParams.set(key, value as string);
			});
			//@ts-ignore
			setSearchParams(searchParams); // Update the searchParams in the URL
		}
	}, [promo, searchParams, setSearchParams]);

	useEffect(() => {
		if (!data) return;

		const accessToken = data['access'];
		const refreshToken = data['refresh'];

		if (accessToken && refreshToken) {
			dispatch(setCredentials({ accessToken, refreshToken }));

			const parsed = parseJwt(accessToken);
			navigate('/', {
				replace: true
			});

			// navigate(isNew === 'False' ? '/' : '/registration/form', {
			// 	replace: true
			// });
		}
	}, [accessToken, isNew, refreshToken, data, dispatch, navigate]);

	const handleSubmit = async (values: SignInEditForm) => {
		await signIn({
			email: values.email!,
			password: values.password!
		});
	};

	const formik = useFormik<SignInEditForm>({
		validationSchema: signInFormValidationSchema,
		enableReinitialize: true,
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			email: email || '',
			password: ''
		},
		onSubmit: values => handleSubmit(values)
	});

	const handleContinueWithEmail = () => {
		formik.handleSubmit();
	};

	return (
		<div className={styles.login}>
			<Text variant="inter/32/semi" className={styles.loginHeader}>
				Welcome to Muraena
			</Text>

			<FormikProvider value={formik}>
				<Form className={styles.form} layout="vertical" requiredMark={false} name="registration">
					<FormikInput className={styles.input} name="email" label="Email" size="large" placeholder="Email" />
					<FormikInputPassword
						className={styles.input}
						name="password"
						label="Password"
						size="large"
						placeholder="Password"
					/>
					{error && (error as FetchErrorWithData).data?.detail && (
						<div style={{ marginBottom: '16px', textAlign: 'left' }}>
							<Text variant="inter/14/regular" color="red">
								{(error as FetchErrorWithData).data.detail}
							</Text>
						</div>
					)}
					<button
						type="button"
						onClick={() => {
							navigate('/forgot_password');
						}}
						className={cn(styles.link, styles.forgot)}>
						<span>Forgot password?</span>
					</button>
					<Button
						className={styles.loginButton}
						type="primary"
						block
						onClick={handleContinueWithEmail}
						disabled={!formik.isValid}>
						Sign in
					</Button>
					<Divider />
				</Form>
			</FormikProvider>

			<GoogleSocialButton />

			<Text variant="inter/16/regular" color="another_grey">
				New to Muraena?{' '}
				<button
					onClick={() => {
						navigate('/login');
					}}
					className={styles.link}>
					<span>Create an account</span>
				</button>
			</Text>
		</div>
	);
};
