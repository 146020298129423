import { useSearchParams } from 'react-router-dom';

import { Icon, Text } from 'shared/components/ui';
import { useCreatePasswordMutation } from 'services';

import styles from './index.module.scss';

export const CheckEmail = () => {
	const [searchParams] = useSearchParams();

	const email = searchParams.get('email');
	const reset = searchParams.get('reset');

	const [createPassword] = useCreatePasswordMutation();

	const handleSend = async () => {
		await createPassword({ email: email!, action: reset ? 'reset' : 'create' });
	};

	return (
		<div className={styles.container}>
			<Icon icon="check-mail" />
			<Text variant="inter/32/semi" className={styles.header}>
				Check your email
			</Text>
			<div className={styles.body}>
				<Text variant="inter/16/medium" color="another_grey" className={styles.bodyHeader}>
					{reset ? 'We’ve sent a link to create a new password to ' : 'We’ve sent an email to '}<span>{email}</span>
				</Text>
				<Text variant="inter/16/regular">
					Please check your email and click the link provided to create a password.
				</Text>
				<Text variant="inter/16/regular">If you don’t see our email, please check your spam.</Text>
			</div>

			<span onClick={handleSend}>
				<Text variant="inter/15/medium" className={styles.button}>
					Send again
				</Text>
			</span>
		</div>
	);
};
