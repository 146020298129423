import { useNavigate } from 'react-router-dom';
import qs from 'query-string';

import { SearchParams } from 'shared/generated-models';
import { SEARCH_LOADING_SIZE } from 'features/search/models';

export const useCompanySearch = () => {
    const navigate = useNavigate();

    return (values: SearchParams, state?: any, newTab?: boolean) => {
        const cleanParams = Object.entries(values)
            .filter(([_, v]) => (Array.isArray(v) ? v.length > 0 : v != null))
            .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

        if ('linkedin_url' in cleanParams && !cleanParams['linkedin_url']) delete cleanParams['linkedin_url'];

        if ('total_funding_start' in cleanParams || 'total_funding_end' in cleanParams) {
            // @ts-ignore
            cleanParams['total_funding_amount'] = [cleanParams['total_funding_start'] || null, cleanParams['total_funding_end'] || null];
        }
        if ('total_funding_start' in cleanParams) delete cleanParams['total_funding_start'];
        if ('total_funding_end' in cleanParams) delete cleanParams['total_funding_end'];

        const searchString = qs.stringify(
            { ...cleanParams, size: SEARCH_LOADING_SIZE },
            {
                arrayFormat: 'bracket-separator',
                arrayFormatSeparator: ','
            }
        );

        if (newTab) {
            const url = `/companies_search/results?${searchString}`;
            window.open(url, '_blank');
            return;
        }

        navigate(
            {
                pathname: '/companies_search/results',
                search: searchString
            },
            { state }
        );
    };
};
