import { useEffect } from 'react';

import { setHeaderTitle, useAppDispatch } from 'store';
import { EnrichmentHistory } from 'features/enrichment';
import { ExpiredPlanModal, useIsExpired } from 'features/auth';

export const Enrichment = () => {
	const dispatch = useAppDispatch();
	const isExpired = useIsExpired();

	useEffect(() => {
		dispatch(setHeaderTitle(''));
	}, [dispatch]);

	return (
		<>
			<EnrichmentHistory />
			{isExpired && <ExpiredPlanModal />}
		</>
	);
};
