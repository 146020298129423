import { FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form } from 'antd';

import { Button, FormikInput, Text } from 'shared/components/ui';
import { useAppDispatch } from 'store';
import { setCredentials } from 'store/credentials';
import { useLazyGetCheckEmailQuery } from 'services';
import { parseJwt } from 'shared/utils';
import { Analytics } from 'features/analytics';
import { Divider, GoogleSocialButton } from 'features/auth/components';
import { LoginEditForm } from 'shared/models';
import { loginFormValidationSchema } from 'features/registration';
import { useLocalStorage } from 'shared/hooks';

import styles from './index.module.scss';

export const LoginForm = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [promo, setPromo] = useLocalStorage('promo', {});

	const accessToken = searchParams.get('access');
	const refreshToken = searchParams.get('refresh');
	const isNew = searchParams.get('is_new');

	const [getCheckEmail, { data }] = useLazyGetCheckEmailQuery();

	useEffect(() => {
		const code = searchParams.get('pipedrive_code');
		const hubspot_code = searchParams.get('hubspot_code');
		const appsumo_code = searchParams.get('appsumo_code');
		const coupon = searchParams.get('coupon');
		const deal = searchParams.get('deal');
		const package_user = searchParams.get('package');
		let newPromo = {...promo};
		if (code) newPromo = {...newPromo, 'pipedrive_code': code};
		if (hubspot_code) newPromo = {...newPromo, 'hubspot_code': hubspot_code};
		if (appsumo_code) newPromo = {...newPromo, 'appsumo_code': appsumo_code};
		if (coupon) newPromo = {...newPromo, 'coupon': coupon};
		if (deal) newPromo = {...newPromo, 'deal': deal};
		if (package_user) newPromo = {...newPromo, 'package': package_user};
		setPromo(newPromo);
	}, [searchParams])

	useEffect(() => {
		if (accessToken && refreshToken) {
			dispatch(setCredentials({ accessToken, refreshToken }));

			const parsed = parseJwt(accessToken);

			Analytics.trackLogin({
				isNew: isNew === 'True',
				id: parsed.user_id.toString()
			});

			navigate(isNew === 'False' ? '/' : '/registration/form', {
				replace: true
			});
		}
	}, [accessToken, dispatch, isNew, navigate, refreshToken]);

	useEffect(() => {
		if (!data) return;
		const email = encodeURIComponent(formik.values.email!);
		if (data.existing_user && data.has_google_auth && !data.has_password) {
			navigate(`/sign-up-existing?email=${email}`);
			return;
		}
		if (data.existing_user && data.has_password && data.is_active) {
			navigate(`/sign-in?email=${email}`);
			return;
		}
		if (data.existing_user && data.has_password && !data.is_active) {
			navigate(`/finish_registration?email=${email}`);
			return;
		}
		if (!data.existing_user) {
			navigate(`/sign-up?email=${encodeURIComponent(email)}`);
			return;
		}
		// if (!data.has_password) {
		// 	navigate('/finish_registraion');
		// 	return;
		// }
		if (data.existing_user) {
			navigate('/sign-in');
			return;
		}
	}, [data]);

	const handleSubmit = async (values: LoginEditForm) => {
		await getCheckEmail({
			email: encodeURIComponent(values.email!)
		});
	};

	const formik = useFormik<LoginEditForm>({
		validationSchema: loginFormValidationSchema,
		enableReinitialize: true,
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			email: ''
		},
		onSubmit: values => handleSubmit(values)
	});

	const handleContinueWithEmail = () => {
		formik.handleSubmit();
	};

	return (
		<div className={styles.login}>
			<Text variant="inter/32/semi" className={styles.loginHeader}>
				Welcome to Muraena
			</Text>

			<FormikProvider value={formik}>
				<Form className={styles.form} layout="vertical" requiredMark={false} name="registration">
					<FormikInput className={styles.input} name="email" label="Email" size="large" placeholder="Email" />
					<Button
						className={styles.loginButton}
						type="primary"
						block
						onClick={handleContinueWithEmail}
						disabled={!formik.isValid}>
						Continue with email
					</Button>
					<Divider />
				</Form>
			</FormikProvider>

			<GoogleSocialButton />
		</div>
	);
};
