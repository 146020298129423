import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';

import { CompanyNew } from 'shared/generated-models';
import { datePeriod, getDuration } from 'features/candidate/utils';
import { CandidateProfileAvatar, ShowMoreText } from 'shared/components';
import { Text, TextButton } from 'shared/components/ui';
import { normalizeBrackets } from 'features/candidate/utils';

import styles from './index.module.scss';

interface Props {
	data: CompanyNew;
	className: string;
}

const extractDomain = (url?: string): string => {
	if (!url) return '';
	try {
		const { protocol, host } = new URL(url);
		return `${protocol}//${host}`;
	} catch (error) {
		console.error('Invalid URL:', url);
		return '';
	}
};

export const Companies: FC<Props> = ({ data, className }: Props) => {
	const [logo, setLogo] = useState<string | null>(null);
	const duration = getDuration(data.startDate, data.endDate);
	const normalizedSummary = normalizeBrackets(data.companySummary);

	useEffect(() => {
		const fetchLogo = async () => {
			try {
				const logoUrl = `https://logo.clearbit.com/${extractDomain(data?.companyUrl)}`;
				const response = await fetch(logoUrl);
				if (response.ok) {
					setLogo(logoUrl);
				} else {
					setLogo(null);
				}
			} catch (error) {
				console.error('Failed to fetch logo:', error);
				setLogo(null);
			}
		};

		fetchLogo();
	}, [data]);

	const showCompanySearch = () => {
		if (!data.company) return;
		const host = window.location.host;
		const baseUrl = `http://${host}/search/results`;
		const queryParams = `?companies[]=${encodeURIComponent(data.company)}&page=1&size=100`;
		const fullUrl = `${baseUrl}${queryParams}`;

		window.open(fullUrl, '_blank');
	};

	const openCompanyLink = () => {
		if (data.companyLinkedInUrl) {
			const absoluteUrl = data.companyLinkedInUrl.startsWith('http')
				? data.companyLinkedInUrl
				: `http://${data.companyLinkedInUrl}`;
			window.open(absoluteUrl, '_blank');
		}
	};

	return (
		<div className={cn(styles.experience, className)}>
			{logo ? (
				<img className={styles.logo} src={logo} alt="logo" />
			) : (
				<CandidateProfileAvatar className={styles.experienceAvatar}>💼</CandidateProfileAvatar>
			)}
			<div className={styles.experienceWrapper}>
				<div className={styles.experienceTop}>
					<Text variant="inter/15/semi">{data.companyTitle}</Text>
					{/* {data.current === 1 && (
						<Text variant="inter/12/medium" color="dark_green" className={styles.experienceCurrent}>
							Current job
						</Text>
					)} */}
				</div>
				<div className={styles.experienceCompanyWrapper}>
					<span onClick={openCompanyLink}>
						<Text
							component="p"
							className={styles.experienceCompany}
							color={data.companyLinkedInUrl ? 'light_blue' : 'black'}>
							{data.company}
						</Text>
					</span>
					<span style={{ marginBottom: 10 }}>
						<TextButton onClick={showCompanySearch}>View company employees</TextButton>
					</span>
				</div>
				<div className={styles.experienceInfo}>
					<Text component="p" color="grey">
						<>
							{datePeriod(data.startDate, data.endDate)}
							{duration && ` · ${duration}`}
						</>
					</Text>
					<Text component="p" color="grey">
						{data.companyLocation}
					</Text>
				</div>
				{normalizedSummary && <ShowMoreText text={normalizedSummary} />}
			</div>
		</div>
	);
};
