import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form } from 'antd';

import { Button, FormikInput, FormikInputPassword, Icon, Text } from 'shared/components/ui';
import { useAppDispatch } from 'store';
import { setCredentials } from 'store/credentials';
import { useConfirmCreatePasswordMutation, useSignUpMutation } from 'services';
import { parseJwt } from 'shared/utils';
import { Analytics } from 'features/analytics';
import { CreatePasswordForm as CreatePasswordEditForm, ProfileEditForm } from 'shared/models';
import { createPasswordFormValidationSchema, profileFormValidationSchema } from 'features/registration';

import styles from './index.module.scss';

export const SignUpForm = () => {
	const [error, setError] = useState<string | null>(null);
	const [password, setPassword] = useState<string | null>(null);
	const [verify, setVerify] = useState<boolean>(false);
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const email = decodeURIComponent(searchParams.get('email') || '');

	const [signUp] = useSignUpMutation();

	const acceptPassword = async (values: CreatePasswordEditForm) => {
		if (values.password !== values.confirm_password) {
			setError('Passwordss do not match!');
			return;
		}
		setPassword(values.password);
	};

	const handleSubmit = (values: ProfileEditForm) => {
		signUp({
			email: email!,
			first_name: values.first_name!,
			last_name: values.last_name!,
			password: password!,
			confirm_password: password!
		});
		if (!verify) setVerify(true);
	};

	const formik = useFormik<CreatePasswordEditForm>({
		validationSchema: createPasswordFormValidationSchema,
		enableReinitialize: true,
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			password: '',
			confirm_password: ''
		},
		onSubmit: values => acceptPassword(values)
	});

	const formikName = useFormik<ProfileEditForm>({
		validationSchema: profileFormValidationSchema,
		enableReinitialize: true,
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			first_name: '',
			last_name: ''
		},
		onSubmit: values => handleSubmit(values)
	});

	const handleContinue = () => {
		formik.handleSubmit();
	};

	const handleSubmitForm = () => {
		formikName.handleSubmit();
	};

	return (
		<div className={styles.login}>
			{!verify && !password && (
				<>
					<Text variant="inter/32/semi" className={styles.loginHeader}>
						Create a password
					</Text>
					<FormikProvider value={formik}>
						<Form className={styles.form} layout="vertical" requiredMark={false} name="registration">
							<FormikInputPassword
								className={styles.input}
								name="password"
								label="Password"
								size="large"
								placeholder={'Password'}
							/>
							<FormikInputPassword
								className={styles.input}
								name="confirm_password"
								label="Confirm password"
								size="large"
								placeholder={'Confirm password'}
							/>
							{error && (
								<div style={{ marginBottom: '16px', textAlign: 'left' }}>
									<Text variant="inter/14/regular" color="red">
										{error}
									</Text>
								</div>
							)}
							<Button
								className={styles.loginButton}
								type="primary"
								block
								onClick={handleContinue}
								disabled={!formik.isValid}>
								{'Continue'}
							</Button>
							<Text variant="inter/16/regular" color="another_grey">
								Already have an account?{' '}
								<button
									onClick={() => {
										navigate('/sign-in');
									}}
									className={styles.link}>
									<span>Sign in</span>
								</button>
							</Text>
							<Text variant="inter/16/regular">
								<button
									onClick={() => {
										navigate('/sign-in');
									}}
									className={styles.link}>
									<span>Use a different email</span>
								</button>
							</Text>
						</Form>
					</FormikProvider>
				</>
			)}
			{!verify && !!password && (
				<>
					<Text variant="inter/32/semi" className={styles.loginHeader}>
						Сomplete registration
					</Text>
					<FormikProvider value={formikName}>
						<Form className={styles.form} layout="vertical" requiredMark={false} name="registration">
							<div className={styles.email}>
								<Text variant="inter/14/medium">Email</Text>
								<Text variant="inter/14/regular" color="grey">
									{email}
								</Text>
							</div>
							<FormikInput
								className={styles.input}
								name="first_name"
								label="First Name"
								size="large"
								placeholder={'First Name'}
							/>
							<FormikInput
								className={styles.input}
								name="last_name"
								label="Last Name"
								size="large"
								placeholder={'Last Name'}
							/>
							{/* {error && (
								<div style={{ marginBottom: '16px' }}>
									<Text variant="inter/14/regular" color="red">
										{error}
									</Text>
								</div>
							)} */}
							<div className={styles.gap}>
								<Button
									className={styles.loginButton}
									type="primary"
									block
									onClick={handleSubmitForm}
									disabled={!formikName.isValid}>
									{'Submit'}
								</Button>
							</div>
						</Form>
					</FormikProvider>
				</>
			)}
			{verify && (
				<>
					<Icon icon="check-mail" />
					<Text variant="inter/32/semi" className={styles.header}>
						Verify your email
					</Text>
					<div className={styles.body}>
						<Text variant="inter/16/medium" color="another_grey" className={styles.bodyHeader}>
							We’ve sent an email to <span>{email}</span>
						</Text>
						<Text variant="inter/16/regular">
							Please check your email and click the link provided to create a password.
						</Text>
						<Text variant="inter/16/regular">If you don’t see our email, please check your spam.</Text>
					</div>

					<span onClick={handleSubmitForm}>
						<Text variant="inter/15/medium" className={styles.button}>
							Send again
						</Text>
					</span>
				</>
			)}
		</div>
	);
};
