import { FC, ReactNode } from 'react';
import cn from 'classnames';

import { Icon, Text } from 'shared/components/ui';
import { useAppSelector } from 'store/redux-hooks';

import styles from './index.module.scss';

interface Props {
	label: ReactNode;
	onClick: (arg: any) => void;
	className?: string;
	negative?: boolean;
	handleSwitch?: (arg: any) => void;
}

export const Tag: FC<Props> = ({ label, onClick, className, negative, handleSwitch }: Props) => {
	const searchType = useAppSelector(state => state.search);

	const handleDivClick = (e: React.MouseEvent) => {
		e.stopPropagation();
		if (handleSwitch) handleSwitch(e);
	};

	const handleOnClick = (e: React.MouseEvent) => {
		e.stopPropagation();
		onClick(e);
	};

	return (
		<div
			className={cn(
				styles.tag,
				className,
				{ [styles.tagNegative]: negative },
				{ [styles.tagPeople]: searchType === 'people' && !negative },
				{ [styles.tagCompanies]: searchType === 'companies' && !negative }
			)}
			onClick={handleDivClick}>
			<Text variant="inter/14/medium">{label}</Text>
			<button
				style={{ zIndex: 101 }}
				type="button"
				onClick={handleOnClick}
				className={cn({ [styles.tagNegativeButton]: negative })}>
				<Icon icon="close" />
			</button>
		</div>
	);
};
