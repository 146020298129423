import { FC, useState } from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import cn from 'classnames';

import { CenterModal, ModalFooter } from 'shared/components';
import { Button, Checkbox, Icon, openNotification, Text } from 'shared/components/ui';
import { useGetCurrentUserQuery } from 'services';
import { CandidatesWithCount } from 'features/csv-export';
import { isPlural } from 'shared/utils';
import { CalendlyWidget } from 'features/iframes';

import styles from './index.module.scss';

interface Props {
	onClose: () => void;
	candidates: CandidatesWithCount;
	handleExport: (isFull: boolean, onlyWorkEmails: boolean) => void;
	isFree?: boolean;
	loading: boolean;
}

export const ExportCsvCompanyModal: FC<Props> = ({ onClose, candidates, handleExport, isFree, loading }: Props) => {
	const [isFull, setIsFull] = useState(false);
	const [onlyWorkEmails, setOnlyWorkEmails] = useState(false);
	const { data } = useGetCurrentUserQuery();

	const handleCheckbox = (e: CheckboxChangeEvent) => {
		setIsFull(e.target.checked);
	};

	const handleOnlyWorkEmailsCheckbox = (e: CheckboxChangeEvent) => {
		setOnlyWorkEmails(e.target.checked);
	};

	const handleSubmit = async () => {
		if (data && data.credits < candidates.count) {
			openNotification({
				key: 'export-notification',
				text: 'You do not have enough credits.',
				closable: true
			});
			return;
		}
		try {
			await handleExport(isFull, onlyWorkEmails);
		} catch (e) {
			console.error(e);
		}
	};

	if (data && data.credits < 1) return <CalendlyWidget handleClose={onClose} variant="search" />;

	return (
		<CenterModal onClose={onClose} icon="export" iconColor="green" title="Export companies">
			<div className={styles.body}>
				<div className={styles.count}>
					<Icon icon="person" />
					<Text variant="inter/14/medium" color="dark_blue">
						{candidates.count} {isPlural(candidates.count) ? 'companies' : 'company'}
					</Text>
				</div>
				{/* <Checkbox className={styles.checkbox} checked={isFull} onChange={handleCheckbox}>
					<Text variant="inter/14/medium">Include all fields in CSV</Text>
				</Checkbox>
				<Checkbox
					className={cn(styles.checkbox, styles.checkboxSecond)}
					checked={onlyWorkEmails}
					onChange={handleOnlyWorkEmailsCheckbox}>
					<Text variant="inter/14/medium">Export leads only with work email</Text>
				</Checkbox> */}
				<Text color="grey" component="p">
					{/* By default CSV includes basic information about the lead. You can choose to include all information.{' '}
					<br />
					<br /> */}
					After the CSV file is created, it will be downloaded automatically.
				</Text>
			</div>
			<ModalFooter>
				<Button type="ghost" onClick={onClose}>
					Cancel
				</Button>
				{loading ? (
					<Button type="secondary" onClick={() => {}} loading={loading} disabled={true}>
						Preparing
					</Button>
				) : (
					<Button type="primary" onClick={handleSubmit}>
						Download
					</Button>
				)}
			</ModalFooter>
		</CenterModal>
	);
};
