import { CheckEmail } from 'features/auth';

import styles from './index.module.scss';

export const CheckEmailPage = () => {
	return (
		<section className={styles.container}>
			<main className={styles.login}>
				<CheckEmail />
			</main>
		</section>
	);
};
