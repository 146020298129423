import React, { FC, useState, WheelEvent, useRef } from 'react';
import cn from 'classnames';
import type { ValueType } from '@rc-component/mini-decimal';
import { useGetCurrentUserQuery } from 'services';
import { Button, Checkbox, Dropdown, Icon, InputNumber, Text, TextButton } from 'shared/components/ui';
import { useFilters } from 'features/search';

import styles from './index.module.scss';

interface Props {
	className?: string;
	controls: {
		main: {
			togglePage: () => void;
			count: number;
			isAllSelected: boolean;
		};
		extra?: {
			selectPage: () => void;
			selectAll: () => void;
			selectCount: (count: number) => void;
		};
	};
	hint?: string;
	total?: number;
	countValue: number;
	setCountValue: (count: number) => void;
}

export const TableSelection: FC<Props> = ({ className, hint, controls, total, countValue, setCountValue }) => {
	const { data: user } = useGetCurrentUserQuery();
	const filters = useFilters();

	const triggerRef = useRef<HTMLDivElement>(null);
	const [isSelectOpen, setIsSelectOpen] = useState(false);

	const preventNumberInputScroll = (e: WheelEvent<HTMLInputElement>) => {
		// @ts-ignore
		e.target.blur();

		setTimeout(() => {
			// @ts-ignore
			e.target.focus();
		}, 0);
	};

	const { togglePage, isAllSelected, count } = controls.main;

	const defaultTogglePage = () => {
		if (filters.page !== 1) {
			togglePage();
			return;
		}
		if (isAllSelected) {
			setCountValue(0);
			controls.extra?.selectCount(0);
		} else {
			let count = 100;
			if (total && total < count) count = total;
			controls.extra?.selectCount(count);
			setCountValue(count);
			if (triggerRef.current) {
				triggerRef.current.click();
			}
		}
	};

	const handleSelection = () => {
		if (user?.credits && countValue > user?.credits) {
			if (total && total < user?.credits) {
				controls.extra?.selectCount(total);
				setCountValue(total);
				return;
			}
			controls.extra?.selectCount(user?.credits);
			setCountValue(user?.credits);
			return;
		}

		if (total && total < countValue) {
			controls.extra?.selectCount(total);
			setCountValue(total);
			return;
		}

		controls.extra?.selectCount(countValue);
	};

	const handleInputChange = (input: ValueType | null) => {
		const num = input as number;
		if (num >= 0) setCountValue(num);
	};

	return (
		<div className={cn(styles.selectWrapper, className)}>
			<Checkbox className={styles.checkbox} onChange={defaultTogglePage} checked={isAllSelected} />
			<Text color={count === 0 ? 'grey' : 'black'}>{count === 0 ? hint : `Selected ${count}`}</Text>
			{controls.extra && (
				<Dropdown
					trigger={['click']}
					onOpenChange={setIsSelectOpen}
					dropdownRender={() => (
						<div className={styles.menu}>
							<TextButton className={styles.menuButton} onClick={controls.extra?.selectPage}>
								Select this page
							</TextButton>
							<TextButton className={styles.menuButton} onClick={controls.extra?.selectAll}>
								Select All
							</TextButton>
							<div className={styles.menuSelection}>
								<Text component="p" color="black" className={styles.menuSelect}>
									Select
								</Text>
								<InputNumber
									onWheel={preventNumberInputScroll}
									placeholder="20"
									controls={false}
									value={countValue || undefined}
									onChange={handleInputChange}
									type="number"
									className={styles.menuInput}
								/>
							</div>
							<Button className={styles.menuButtonApply} disabled={!countValue} onClick={handleSelection}>
								Select
							</Button>
						</div>
					)}>
					<div className={styles.selectTriggerWrapper} ref={triggerRef}>
						<Icon
							className={cn(styles.selectTrigger, {
								[styles.selectTriggerOpen]: isSelectOpen
							})}
							icon="arrow-down"
						/>
					</div>
				</Dropdown>
			)}
		</div>
	);
};
