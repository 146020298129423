import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form } from 'antd';

import { Button, FormikInput, Text } from 'shared/components/ui';
import { useCreatePasswordMutation } from 'services';
import { LoginEditForm } from 'shared/models';
import { loginFormValidationSchema } from 'features/registration';

import styles from './index.module.scss';

export const ForgotPasswordForm = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const email = searchParams.get('email');
	const [error, setError] = useState<string | null>(null);

	const [createPassword] = useCreatePasswordMutation();

	const handleSubmit = async (values: LoginEditForm) => {
		const result = await createPassword({ email: values.email!, action: 'reset' });
		if ('error' in result) {
			setError('Email was not found');
			return;
		}
		navigate(`/check-email?email=${values.email}&reset=True`);
	};

	const formik = useFormik<LoginEditForm>({
		validationSchema: loginFormValidationSchema,
		enableReinitialize: true,
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			email: email || ''
		},
		onSubmit: values => handleSubmit(values)
	});

	const handleContinueWithEmail = () => {
		formik.handleSubmit();
	};

	return (
		<div className={styles.login}>
			<Text variant="inter/32/semi" className={styles.loginHeader}>
				Forgot your password?
			</Text>
			<Text variant="inter/16/medium" color="another_grey" className={styles.loginSubheader}>
				We’ll send you a link to create a new password.
			</Text>

			<FormikProvider value={formik}>
				<Form className={styles.form} layout="vertical" requiredMark={false} name="registration">
					<FormikInput className={styles.input} name="email" label="Email" size="large" placeholder="Email" />
					{error && (
						<div style={{ marginBottom: '16px', textAlign: 'left' }}>
							<Text variant="inter/14/regular" color="red">
								{error}
							</Text>
						</div>
					)}
					<Button
						className={styles.loginButton}
						type="primary"
						block
						onClick={handleContinueWithEmail}
						disabled={!formik.isValid}>
						Submit
					</Button>
				</Form>
			</FormikProvider>
			<Text variant="inter/16/regular">
				<button
					onClick={() => {
						navigate('/sign-in');
					}}
					className={styles.link}>
					<span>Back</span>
				</button>
			</Text>
		</div>
	);
};
