import { useEffect } from 'react';

import { setHeaderTitle, useAppDispatch } from 'store';
import { CompanyResultsList, SideSearchFormCompany } from 'features/search/components';

import styles from './index.module.scss';

export const CompanyResults = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setHeaderTitle('Search'));
	}, [dispatch]);

	return (
		<section className={styles.container}>
			<SideSearchFormCompany />
			<CompanyResultsList />
		</section>
	);
};
