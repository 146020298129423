import { useState } from 'react';

import { TextButton, Text, Icon } from 'shared/components/ui';
import { useAppSelector } from 'store/redux-hooks';

import styles from './index.module.scss';

const POPULAR_SEARCHES = [
	{
		id: 1, // tech
		title: 'CTOs and Engineering Leads at SaaS companies that recently raised Series A or Series B funding',
		link: '/search/results?industries[]=SaaS&last_funding_date=Past%20Year&page=1&size=100&title_keywords[]=CTO,Chief%20Technology,Head%20of%20Engineering&total_funding_amount[]=3000000,60000000'
	},
	{
		id: 2, // non-digital
		title: 'Operations managers at Logistics and Supply Chain companies based in Europe',
		link: '/search/results?industries[]=Transportation%2C%20Logistics%2C%20Supply%20Chain%20and%20Storage&job_titles[]=Operations%20%26%20Supply%20Chain&page=1&regions[]=Europe&size=100'
	},
	{
		id: 3, // technographics
		title: 'Marketers at SMB e-commerce retailers using Shopify, based in the US',
		link: '/search/results?countries[]=United%20States&headcount[]=51-200,11-50,201-500&industries[]=Retail,E-Commerce,Retail%20Apparel%20and%20Fashion,Retail%20Luxury%20Goods%20and%20Jewelry&job_titles[]=Marketing%20%26%20PR&page=1&size=100&technography[]=Shopify'
	},
	{
		id: 4, // company keywords
		title: 'Partnership Managers at email and digital marketing agencies',
		link: '/search/results?keywords[]=email%20marketing%20agency,digital%20marketing%20agency&page=1&size=100&title_keywords[]=Partnership'
	},
	{
		id: 5, // lookalike
		title: 'Companies similar to Semrush, Ahrefs and Moz',
		link: '/search/results?lookalike[]=semrush.com,ahrefs.com,moz.com&page=1&size=100'
	}
];

const POPULAR_SEARCHES_COMPANIES = [
	{
		id: 1, // tech
		title: 'CTOs and Engineering Leads at SaaS companies that recently raised Series A or Series B funding',
		link: '/search/results?industries[]=SaaS&last_funding_date=Past%20Year&page=1&size=100&total_funding_amount[]=3000000,60000000'
	},
	{
		id: 2, // non-digital
		title: 'Operations managers at Logistics and Supply Chain companies based in Europe',
		link: '/search/results?industries[]=Transportation%2C%20Logistics%2C%20Supply%20Chain%20and%20Storage&size=100'
	},
	{
		id: 3, // technographics
		title: 'Marketers at SMB e-commerce retailers using Shopify, based in the US',
		link: '/search/results?company_countries[]=United%20States&headcount[]=51-200,11-50,201-500&industries[]=Retail,E-Commerce,Retail%20Apparel%20and%20Fashion,Retail%20Luxury%20Goods%20and%20Jewelry&page=1&size=100&technography[]=Shopify'
	},
	{
		id: 4, // company keywords
		title: 'Partnership Managers at email and digital marketing agencies',
		link: '/search/results?keywords[]=email%20marketing%20agency,digital%20marketing%20agency&page=1&size=100'
	},
	{
		id: 5, // lookalike
		title: 'Companies similar to Semrush, Ahrefs and Moz',
		link: '/search/results?lookalike[]=semrush.com,ahrefs.com,moz.com&page=1&size=100'
	}
];

export const PopularSearch = () => {
	const [open, setOpen] = useState(false);
	const searchItemsCount = POPULAR_SEARCHES.length;
	const maxItems = open ? searchItemsCount : 5;
	const buttonText = open ? 'See less' : `See all (${searchItemsCount})`;
	const searchType = useAppSelector(state => state.search);

	const handleClick = () => {
		setOpen(!open);
	};

	const searches = searchType === 'people' ? POPULAR_SEARCHES : POPULAR_SEARCHES_COMPANIES;

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>
				<Text variant="inter/16/regular" color="grey">
					ICP examples to kick-off your search
				</Text>
			</div>
			<div className={styles.card}>
				{searches.slice(0, maxItems).map(searchItem => (
					<a
						key={searchItem.id}
						href={
							searchType === 'people'
								? searchItem.link
								: searchItem.link.replace('search', 'companies_search')
						}
						target="_blank"
						rel="noreferrer"
						className={styles.link}>
						<div className={styles.cardItem}>
							<Text variant="inter/16/regular" color="grey">
								{searchItem.id}.
							</Text>
							<div className={styles.cardText}>
								<Text variant="inter/16/regular" color="light_blue">
									{searchItem.title}
								</Text>
								{/* <Text variant="inter/16/regular" color="grey" className={styles.text}>
									{searchItem.body}
								</Text> */}
							</div>
						</div>
					</a>
				))}
				{/* <div className={styles.seeMore}>
					<Text className={styles.seeMoreText} variant="inter/14/regular" color="grey">
						<TextButton onClick={handleClick}>{buttonText}</TextButton>
					</Text>
				</div> */}
			</div>
		</div>
	);
};
