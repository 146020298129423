import { createApi } from '@reduxjs/toolkit/query/react';

import {
	BillingData,
	CreatePasswordPayload,
	ConfirmCreatePasswordPayload,
	CustomPackage,
	Package,
	Plan,
	Price,
	SearchHistory,
	SearchHistoryPayload,
	User,
	UserFull,
	SignInPayload,
	SignUpPayload,
	ConfirmEmailPayload
} from 'shared/generated-models';
import { userInterceptor } from 'services/userInterceptor';
import { environment } from 'environments/environment';
import { SearchParams } from 'shared/generated-models';
import { CheckEmail, LoginEditForm } from 'shared/models';
import { voidFn } from 'shared/utils';

const redirectUrl = `${window.location.origin}/login`;
const errorUrl = `${window.location.origin}/login-error`;
export const authLink = (() => {
	const params = new URLSearchParams(window.location.search);
	const code = params.get('pipedrive_code');
	const hubspot = params.get('hubspot_code');
	const appsumo = params.get('appsumo_code');
	const coupon = params.get('coupon');
	const deal = params.get('deal');
	const package_user = params.get('package');
	const baseLink = `${environment.userUrl}/login/google-oauth2`;
	let link = `${baseLink}?redirect_to=${redirectUrl}&error_to=${errorUrl}`;
	if (code) {
		link += `&pipedrive_code=${code}`;
	}
	if (hubspot) {
		link += `&hubspot_code=${hubspot}`;
	}
	if (appsumo) {
		link += `&appsumo_code=${appsumo}`;
	}
	if (deal) {
		link += `&deal=${deal}`;
	}
	if (coupon) {
		link += `&coupon=${coupon}`; // Adding the "coupon" parameter to the link
	}
	if (package_user) {
		link += `&package=${package_user}`; // Adding the "coupon" parameter to the link
	}
	return link;
})();

export const emptyUser: User = {
	id: 0,
	email: '',
	first_name: '',
	last_name: '',
	company: '',
	avatar: '',
	billing_plan: '',
	credits: 0
};

export const authService = createApi({
	reducerPath: 'authService',
	tagTypes: ['User'],
	baseQuery: userInterceptor,
	endpoints: builder => ({
		getCurrentUser: builder.query<User, void>({
			query: () => '/current/',
			providesTags: ['User']
		}),
		getPlans: builder.query<Plan[], void>({
			query: () => ({
				url: '/plans/',
				method: 'GET'
			})
		}),
		getCustomPackages: builder.query<CustomPackage[], void>({
			query: () => ({
				url: '/custom_packages/',
				method: 'GET'
			})
		}),
		createCheckoutSession: builder.mutation<any, Price>({
			query: price => ({
				url: '/payments/checkout/',
				method: 'POST',
				body: { ...price }
			})
		}),
		createCustomCheckoutSession: builder.mutation<any, Package>({
			query: price => ({
				url: '/payments/custom_package_checkout/',
				method: 'POST',
				body: { ...price }
			})
		}),
		createModifyCheckoutSession: builder.mutation<any, void>({
			query: () => ({
				url: '/payments/portal/',
				method: 'GET'
			})
		}),
		getBillingData: builder.query<BillingData, string>({
			query: session_id => ({
				url: `/payments/success/?session_id=${session_id}`,
				method: 'GET'
			}),
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(authService.util.invalidateTags(['User']));
					// dispatch(
					// 	authService.util.updateQueryData('getCurrentUser', voidFn(), user => {
					// 		return { ...user, credits: data.credits, billing_plan: data.billing_plan };
					// 	})
					// );
				} catch (err) {
					console.error(err);
				}
			}
		}),
		updateUser: builder.mutation<User, User>({
			query: user => ({
				url: `/${user.id}/`,
				method: 'PUT',
				body: user
			}),
			invalidatesTags: ['User']
		}),
		logoutUser: builder.mutation<void, void>({
			query: () => ({ url: '/login/logout/', method: 'POST' }),
			invalidatesTags: ['User']
		}),
		getUserSubscription: builder.query<UserFull, void>({
			query: () => '/current/subscription/'
		}),
		getApiToken: builder.query<any, void>({
			query: () => '/client_api/refresh_token/',
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					await queryFulfilled;
					dispatch(authService.util.invalidateTags(['User']));
				} catch (err) {
					console.error(err);
				}
			}
		}),
		saveHistory: builder.mutation<void, SearchHistoryPayload>({
			query: params => ({
				url: `/search_history/`,
				method: 'POST',
				body: params,
				invalidatesTags: ['SearchHistory']
			})
		}),
		getSearchHistory: builder.query<SearchHistory[], void>({
			query: () => ({
				url: `/search_history/`,
				method: 'GET',
				providesTags: ['SearchHistory']
			})
		}),
		getCheckEmail: builder.query<CheckEmail, LoginEditForm>({
			query: form => ({
				url: `/check_email/?email=${form.email}`,
				method: 'GET',
				providesTags: ['']
			})
		}),
		createPassword: builder.mutation<void, CreatePasswordPayload>({
			query: params => ({
				url: `/create_or_reset_password/`,
				method: 'POST',
				body: params,
				invalidatesTags: ['']
			})
		}),
		confirmCreatePassword: builder.mutation<void, ConfirmCreatePasswordPayload>({
			query: params => ({
				url: `/create_or_reset_password/confirm/${params.uid}/${params.token}/`,
				method: 'POST',
				body: {
					password: params.password,
					confirm_password: params.confirm_password
				},
				invalidatesTags: ['']
			})
		}),
		signIn: builder.mutation<void, SignInPayload>({
			query: params => {
				const searchParams = new URLSearchParams(window.location.search);
				const code = searchParams.get('pipedrive_code');
				const hubspot = searchParams.get('hubspot_code');
				const appsumo = searchParams.get('appsumo_code');
				const coupon = searchParams.get('coupon');
				const deal = searchParams.get('deal');
				const package_user = searchParams.get('package');
				let additionalParams = '';
				if (code) {
					additionalParams += `&pipedrive_code=${code}`;
				}
				if (hubspot) {
					additionalParams += `&hubspot_code=${hubspot}`;
				}
				if (appsumo) {
					additionalParams += `&appsumo_code=${appsumo}`;
				}
				if (deal) {
					additionalParams += `&deal=${deal}`;
				}
				if (coupon) {
					additionalParams += `&coupon=${coupon}`;
				}
				if (package_user) {
					additionalParams += `&package=${package_user}`;
				}
				if (additionalParams) additionalParams = additionalParams.replace('&', '?');
				return { url: `/auth/${additionalParams}`, method: 'POST', body: params, invalidatesTags: [''] };
			}
		}),
		signUp: builder.mutation<void, SignUpPayload>({
			query: params => ({
				url: `/sign_up/`,
				method: 'POST',
				body: params,
				invalidatesTags: ['']
			})
		}),
		signUpConfirm: builder.query<void, ConfirmEmailPayload>({
			query: params => ({
				url: `/sign_up/confirm/${params.uid}/${params.token}`,
				method: 'GET',
				providesTags: ['']
			})
		}),
		getResendVerificationLink: builder.query<void, LoginEditForm>({
			query: form => ({
				url: `/resend_verification_link/?email=${form.email}`,
				method: 'GET',
				providesTags: ['']
			})
		})
	})
});

export const {
	useGetPlansQuery,
	useGetCustomPackagesQuery,
	useCreateCheckoutSessionMutation,
	useCreateCustomCheckoutSessionMutation,
	useCreateModifyCheckoutSessionMutation,
	useGetBillingDataQuery,
	useGetCurrentUserQuery,
	useUpdateUserMutation,
	useLogoutUserMutation,
	useGetUserSubscriptionQuery,
	useLazyGetApiTokenQuery,
	useSaveHistoryMutation,
	useGetSearchHistoryQuery,
	useLazyGetCheckEmailQuery,
	useCreatePasswordMutation,
	useConfirmCreatePasswordMutation,
	useSignInMutation,
	useSignUpMutation,
	useLazySignUpConfirmQuery,
	useLazyGetResendVerificationLinkQuery
} = authService;
