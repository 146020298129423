import React, { FC, useEffect } from 'react';
import { Modal } from 'antd';

import { CompanyHeader, CompanyProfile } from 'features/candidate/components';
import { SearchCompany } from 'shared/generated-models';
// import { Analytics } from 'features/analytics';
import { useAppDispatch } from 'store';
import { setOpenCandidateModal } from 'store';

import styles from './index.module.scss';

interface Props {
	candidate: SearchCompany;
	candidates: SearchCompany[];
	page: number;
	onClose: () => void;
	setCandidate: (candidate: SearchCompany | null) => void;
	onChange: (page: number) => void;
	total?: number;
}

export const CompanyModal: FC<Props> = ({ candidate, candidates, onClose, page, setCandidate, onChange, total }: Props) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		// Analytics.trackProfileOpening(candidate.profile.id);
		dispatch(setOpenCandidateModal(true));

		return () => {
			dispatch(setOpenCandidateModal(false));
		};
	}, []);

	return (
		<Modal
			open={true}
			destroyOnClose={true}
			onCancel={onClose}
			footer={null}
			closable={false}
			className={styles.modal}
			transitionName="">
			<CompanyHeader
				data={candidate}
				onClose={onClose}
				candidates={candidates}
				page={page}
				setCandidate={setCandidate}
				onChange={onChange}
				total={total}
			/>
			<CompanyProfile variant="search" company={candidate} />
		</Modal>
	);
};
