import React from 'react';

import { MainLogo } from 'shared/assets/main-logo/mainLogo';

import styles from './index.module.scss';

export const AuthLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	return (
		<>
			<div className={styles.logo}>
				<a href="https://muraena.ai">
					<MainLogo />
				</a>
			</div>
			{children}
		</>
	);
};
