import { FC } from 'react';
import { ConfigProvider, Modal } from 'antd';

import { SearchForm, SearchFormCompany } from 'features/search/components';
import { Icon, Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	onClose?: () => void;
	type: 'people' | 'companies';
}

export const SearchModal: FC<Props> = ({ onClose, type }: Props) => {
	return (
		<ConfigProvider theme={{ components: { Modal: { zIndexPopupBase: 10001 } } }}>
			<Modal
				width={820}
				className={styles.modal}
				open={true}
				destroyOnClose={true}
				onCancel={onClose}
				footer={null}
				closable={false}>
				{type === 'people' ? (
					<SearchForm
						heightClassName={styles.height}
						className={styles.form}
						header={
							<div className={styles.header}>
								<Text component="h2" variant="grotesk/20/bold">
									Search filters
								</Text>
								<button onClick={onClose}>
									<Icon icon="close-big" />
								</button>
							</div>
						}
						variant="modal"
					/>
				) : (
					<SearchFormCompany
						heightClassName={styles.height}
						className={styles.form}
						header={
							<div className={styles.header}>
								<Text component="h2" variant="grotesk/20/bold">
									Search filters
								</Text>
								<button onClick={onClose}>
									<Icon icon="close-big" />
								</button>
							</div>
						}
						variant="modal"
					/>
				)}
			</Modal>
		</ConfigProvider>
	);
};
