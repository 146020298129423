import { useNavigate } from 'react-router-dom';
import { Button, Icon, Text } from 'shared/components/ui';

import styles from './index.module.scss';

export const PasswordChanged = () => {
    const navigate = useNavigate();

	const handleLogin = () => {
		navigate('/sign-in');
	};

	return (
		<section className={styles.container}>
			<main className={styles.login}>
				<div className={styles.wrapper}>
					<div className={styles.body}>
						<Icon icon="success" size={110} />
                        <Text variant="inter/32/semi">Password changed!</Text>
                        <Text variant="inter/16/medium" color="another_grey">Your password has been changed successfully.</Text>
					</div>
					<Button className={styles.button} type="primary" block onClick={handleLogin}>
						Login
					</Button>
				</div>
			</main>
		</section>
	);
};
