import { Text } from 'shared/components/ui';
import { LoginForm } from 'features/auth';

import styles from './index.module.scss';

export const Login = () => {
	return (
		<section className={styles.container}>
			<main className={styles.login}>
				<LoginForm />
				<Text
					variant="grotesk/12/regular"
					color="another_grey"
					lineHeight={106}
					component="p"
					className={styles.loginHint}>
					By&nbsp;proceeding, you agree to&nbsp;our{' '}
					<a href="https://muraena.ai/terms/" target="_blank" rel="noreferrer">
						Terms of&nbsp;Use
					</a>{' '}
					and confirm you have read our{' '}
					<a href="https://muraena.ai/privacy/" target="_blank" rel="noreferrer">
						Privacy and Cookies Statement
					</a>
					.
				</Text>
			</main>
		</section>
	);
};
