import RcSwitch from 'rc-switch';
import cn from 'classnames';

import styles from './index.module.scss';

interface Props {
	isChecked: boolean;
	setIsChecked: () => void;
}

export const Switch = ({isChecked, setIsChecked}: Props) => {
	return (
		<div className={styles.background}>
			<RcSwitch
				className={cn(styles.switch, { [styles.switchChecked]: isChecked })}
				checked={isChecked}
				onChange={setIsChecked}></RcSwitch>
		</div>
	);
};
