import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { Icon, Text } from 'shared/components/ui';
import { CandidateProfileSkeleton, CompanyInfo } from 'features/candidate/components';
import { SearchCompany, CLevelStaff } from 'shared/generated-models';
import { ProfileVariant } from 'features/candidate';
import { ShowMoreText, ShowMoreTextMultiple } from 'shared/components';
import { CandidateProfileAvatar } from 'shared/components';

import styles from './index.module.scss';

interface Props {
	company?: SearchCompany;
	shared?: boolean;
	className?: string;
	variant: ProfileVariant;
}

interface LeaderProfileProps {
	leader: CLevelStaff;
}

const LeaderProfile = ({ leader }: LeaderProfileProps) => {
	const handleClick = () => {
		window.open(leader.linkedin_url, '_blank');
	};

	return (
		<div className={styles.leader}>
			<Icon icon="person-bold" size={20} className={styles.leaderIcon} />
			<div className={styles.leaderColumn}>
				<div className={styles.leaderRow}>
					<Link
						to={`/search/results?page=0&linkedin_url=${leader.linkedin_url}&size=100&detailed=True`}
						target="_blank"
						rel="noopener,nofollow,noreferrer"
						className={styles.buttonEmployees}>
						<p className={styles.leaderName}>{`${leader.fist_name} ${leader.last_name}`}</p>
					</Link>
					<Icon icon="linkedin-logo" size={16} onClick={handleClick} className={styles.leaderLinkedin} />
				</div>
				<Text variant="inter/14/regular" color="grey">
					{leader.title}
				</Text>
			</div>
		</div>
	);
};

const formatLocation = (city?: string, country?: string) => {
	if (!city && !country) return '';
	if (!country) return city;
	if (!city) return country;
	return `${city}, ${country}`;
};

const companyInitials = (name?: string): string => {
	if (!name) return '';
	const words = name.trim().split(/\s+/);
	const initials = words.map(word => word.charAt(0).toUpperCase()).join('');
	return initials;
};

const extractDomain = (url?: string): string => {
	if (!url) return '';
	try {
		const { protocol, host } = new URL(url);
		return `${protocol}//${host}`;
	} catch (error) {
		console.error('Invalid URL:', url);
		return '';
	}
};

export const CompanyProfile: FC<Props> = ({ company, shared, variant, className }: Props) => {
	const [logo, setLogo] = useState<string | null>(null);

	useEffect(() => {
		const fetchLogo = async () => {
			try {
				const logoUrl = `https://logo.clearbit.com/${extractDomain(company?.website)}`;
				const response = await fetch(logoUrl);
				if (response.ok) {
					setLogo(logoUrl);
				} else {
					setLogo(null);
				}
			} catch (error) {
				console.error('Failed to fetch logo:', error);
				setLogo(null);
			}
		};

		fetchLogo();
	}, [company]);

	if (!company) return <CandidateProfileSkeleton />;

	return (
		<section className={cn(styles.profile, className)}>
			<div className={styles.wrapper}>
				<section className={styles.info}>
					<div className={styles.infoRow}>
						{logo ? (
							<img className={styles.logo} src={logo} alt="logo" />
						) : (
							<CandidateProfileAvatar size={60}>{companyInitials(company.name)}</CandidateProfileAvatar>
						)}
						<div className={styles.infoColumn}>
							<Text variant="inter/20/bold" className={className}>
								{company.name}
							</Text>
							<div className={styles.location}>
								<Icon icon="location" className={styles.locationIcon} />
								<Text color="dark_green" variant="inter/14/medium">
									{formatLocation(company.city, company.country)}
								</Text>
							</div>
						</div>
					</div>
					<div className={styles.icons}>
						{company.website && (
							<a className={styles.iconsWebsite} href={company.website} target="_blank" rel="noreferrer">
								<Icon icon="website" size={20} />
							</a>
						)}
						{company.linkedin_url && (
							<a
								className={styles.iconsLinkedin}
								href={company.linkedin_url}
								target="_blank"
								rel="noreferrer">
								<Icon icon="linkedin-logo" size={20} />
							</a>
						)}
					</div>
				</section>
				<div className={styles.sections}>
					<section>
						<Text component="h3" variant="inter/18/semi" className={styles.title}>
							About
						</Text>
						<ShowMoreTextMultiple className={styles.bio} text={company.description!} />
					</section>
				</div>
				<div className={styles.sections}>
					<Text component="h3" variant="inter/18/semi" className={styles.title}>
						Summary
					</Text>
					<CompanyInfo company={company} />
				</div>
				{company.c_level_staff && (
					<div className={styles.sections}>
						<Text component="h3" variant="inter/18/semi" className={styles.title}>
							C-Level/Leadership
						</Text>
						<div className={styles.column}>
							{company.c_level_staff.map(leader => (
								<LeaderProfile leader={leader} />
							))}
						</div>
					</div>
				)}
			</div>
		</section>
	);
};
