import { CreatePasswordForm } from 'features/auth';

import styles from './index.module.scss';

interface Props {
	reset?: boolean;
}

export const CreatePassword = ({ reset }: Props) => {
	return (
		<section className={styles.container}>
			<main className={styles.login}>
				<CreatePasswordForm reset={reset} />
			</main>
		</section>
	);
};
