import { FC } from 'react';
import cn from 'classnames';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { Icon } from 'shared/components/ui';
import { MainLogoIcon } from 'shared/assets/main-logo/mainLogo';

import styles from './index.module.scss';

interface Props {
	className?: string;
}

export const Navbar: FC<Props> = ({ className }: Props) => {
	const location = useLocation();

	return (
		<aside className={cn(styles.aside, className)}>
			<nav>
				<Link to="search">
					<MainLogoIcon />
				</Link>
				<NavLink
					to="search"
					className={({ isActive }) =>
						cn(styles.link, {
							[styles.linkActive]: isActive || location.pathname.includes('companies_search')
						})
					}>
					<Icon icon="search" />
				</NavLink>
				<NavLink to="history" className={({ isActive }) => cn(styles.link, { [styles.linkActive]: isActive })}>
					<Icon icon="history" />
				</NavLink>
				<NavLink
					to="enrichment"
					className={({ isActive }) => cn(styles.link, { [styles.linkActive]: isActive })}>
					<Icon icon="download-file" size={24} />
				</NavLink>
				{/* <NavLink
					to="integrations"
					className={({ isActive }) => cn(styles.link, { [styles.linkActive]: isActive })}>
					<Icon icon="socket" />
				</NavLink> */}
			</nav>
			{/* <Link to="https://help.app.prog.ai/" target="_blank" rel="noopener,nofollow,noreferrer">
				<Icon className={styles.question} icon="question-circle" />
			</Link> */}
		</aside>
	);
};
