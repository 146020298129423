import { FC, useState } from 'react';

import { ProgAICandidate, SearchCompany } from 'shared/generated-models';
import { Icon, Text, TextButton } from 'shared/components/ui';
import cn from 'classnames';

import styles from './index.module.scss';

interface Props {
	company: SearchCompany;
}

function formatCurrency(value: string) {
	let number = parseFloat(value);
	number /= 100;
	if (number >= 1000000) {
		return `${(number / 1000000).toFixed(1)}M`;
	} else {
		return `${number.toLocaleString()}`;
	}
}

function formatFundingDate(dateString: string) {
	return new Intl.DateTimeFormat('en-US', {
		month: 'short',
		day: 'numeric',
		year: 'numeric'
	}).format(new Date(dateString));
}

function formatNumberWithCommas(number: number) {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

interface TagProps {
	tag: string;
	type: 'intent' | 'techno';
}

const Tag = ({ tag, type }: TagProps) => {
	return (
		<div
			className={cn(
				styles.tag,
				{ [styles.tagIntent]: type === 'intent' },
				{ [styles.tagTech]: type === 'techno' }
			)}>
			<Text variant="inter/14/medium" key={tag}>
				{tag}
			</Text>
		</div>
	);
};

const ITEMS_TO_SHOW = 5;

export const CompanyInfo: FC<Props> = ({ company }: Props) => {
	const [isOpenBuyersIntent, setIsOpenBuyersIntent] = useState(false);
    const [isOpenTechnographics, setIsOpenTechnographics] = useState(false);

	const handleOpenBuyersIntent = () => {
		setIsOpenBuyersIntent(open => !open);
	};

    const handleTechnographics = () => {
		setIsOpenTechnographics(open => !open);
	};

	return (
		<section className={styles.info}>
			{company.company_size_min && company.company_size_max && (
				<div className={styles.row}>
					<Text variant="inter/14/medium" color="grey" className={styles.rowName}>
						Size
					</Text>
					<Text variant="inter/14/medium" color="dark_blue">
						{`${company.company_size_min} - ${company.company_size_max}`}
					</Text>
				</div>
			)}
			{company.total_funding_amount && (
				<div className={styles.row}>
					<Text variant="inter/14/medium" color="grey" className={styles.rowName}>
						Total Funding, $
					</Text>
					<Text variant="inter/14/medium" color="dark_blue">
						{formatCurrency(company.total_funding_amount.toString())}
					</Text>
				</div>
			)}
			{company.last_funding_date && (
				<div className={styles.row}>
					<Text variant="inter/14/medium" color="grey" className={styles.rowName}>
						Last Funding Date
					</Text>
					<Text variant="inter/14/medium" color="dark_blue">
						{formatFundingDate(company.last_funding_date)}
					</Text>
				</div>
			)}
			{company.linkedin_followers && (
				<div className={styles.row}>
					<Text variant="inter/14/medium" color="grey" className={styles.rowName}>
						Linkedin Followers
					</Text>
					<Text variant="inter/14/medium" color="dark_blue">
						{formatNumberWithCommas(company.linkedin_followers)}
					</Text>
				</div>
			)}
			{company.buyers_intent_topics && (
				<div className={styles.row}>
					<Text variant="inter/14/medium" color="grey" className={cn(styles.rowName, styles.rowNameTop)}>
						Buyers Intent
					</Text>
					<div className={styles.tags}>
						<div className={styles.tagWrapper}>
							{company.buyers_intent_topics.slice(0, ITEMS_TO_SHOW).map(tag => (
								<Tag tag={tag} type={'intent'} />
							))}
							{isOpenBuyersIntent &&
								company.buyers_intent_topics
									.slice(ITEMS_TO_SHOW, company.buyers_intent_topics.length)
									.map(tag => <Tag tag={tag} type={'intent'} />)}
							{company.buyers_intent_topics.length > ITEMS_TO_SHOW && (
								<TextButton onClick={handleOpenBuyersIntent}>
									{isOpenBuyersIntent
										? 'See less'
										: `+${company.buyers_intent_topics.length - ITEMS_TO_SHOW} more`}
								</TextButton>
							)}
						</div>
					</div>
				</div>
			)}
            {company.website_technologies && (
				<div className={styles.row}>
					<Text variant="inter/14/medium" color="grey" className={cn(styles.rowName, styles.rowNameTop)}>
						Technographics
					</Text>
					<div className={styles.tags}>
						<div className={styles.tagWrapper}>
							{company.website_technologies.slice(0, ITEMS_TO_SHOW).map(tag => (
								<Tag tag={tag} type={'techno'} />
							))}
							{isOpenTechnographics &&
								company.website_technologies
									.slice(ITEMS_TO_SHOW, company.website_technologies.length)
									.map(tag => <Tag tag={tag} type={'techno'} />)}
							{company.website_technologies.length > ITEMS_TO_SHOW && (
								<TextButton onClick={handleTechnographics}>
									{isOpenTechnographics
										? 'See less'
										: `+${company.website_technologies.length - ITEMS_TO_SHOW} more`}
								</TextButton>
							)}
						</div>
					</div>
				</div>
			)}
		</section>
	);
};
