import { LandingHeader } from 'shared/components/LandingHeader';

import { LandingFooter } from 'shared/components/LandingFooter';
import { useEffect } from 'react';
import Faq from './components';
import styles from './index.module.scss';
import { CheckCircleOutlined } from '@ant-design/icons';

export const PricingPage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<div className="absolute-block pricing-gradient">
				<div className="absolute-block multi-color-gradient"></div>
				<div className="absolute-block white-gradient-bottom"></div>
			</div>
			<LandingHeader />
			<div className={styles.container}>
				<section className={styles.priceSection}>
					<h1 className={styles.sectionTitle}>
						Muraena offers <br /> simple and lean pricing
					</h1>
					<p className={styles.sectionText}>No extra charges, no hidden fees.</p>
					<div className={styles.priceItems}>
						<div className={styles.priceItem}>
							<div className={styles.itemWrapper}>
								<div className={styles.itemContent}>
									<h3 className={styles.itemTitle}>Free</h3>
									<p className={styles.itemText}>50 email credits / month</p>
									<div className={styles.itemPrice}>$0 USD</div>
									<div className={styles.itemDescr}>Per user, per month</div>
									<a href="https://app.muraena.ai/login" className={styles.itemLink}>
										Sign Up
									</a>
									<div className={styles.linkDescr}>No credit card required</div>
								</div>
								<div className={styles.itemFeatures}>
									<h4 className={styles.featuresTitle}>Key features:</h4>
									<ul className={styles.featuresList}>
										<li className={styles.listItem}>
											<CheckCircleOutlined style={{ color: '#55a939' }} />
											<p>50 email credits / month</p>
										</li>
										<li className={styles.listItem}>
											<CheckCircleOutlined style={{ color: '#55a939' }} />
											<p>Technographics data</p>
										</li>
										<li className={styles.listItem}>
											<CheckCircleOutlined style={{ color: '#55a939' }} />
											<p>Buyers Intent insights</p>
										</li>
										<li className={styles.listItem}>
											<CheckCircleOutlined style={{ color: '#55a939' }} />
											<p>Unlimited AI search</p>
										</li>
										<li className={styles.listItem}>
											<CheckCircleOutlined style={{ color: '#55a939' }} />
											<p>Export to CSV / Pipedrive / Lemlist</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className={styles.priceItem}>
							<div className={styles.itemWrapper}>
								<div className={styles.itemContent}>
									<h3 className={styles.itemTitle}>Basic</h3>
									<p className={styles.itemText}>1,000 email credits / month</p>
									<div className={styles.itemPrice}>$19 USD</div>
									<div className={styles.itemDescr}>Per user, per month</div>
									<a href="https://app.muraena.ai/login?package=basic" className={styles.itemLink}>
										Start a free trial
									</a>
									<div className={styles.linkDescr}>No credit card required</div>
								</div>
								<div className={styles.itemFeatures}>
									<h4 className={styles.featuresTitle}>Key features:</h4>
									<ul className={styles.featuresList}>
										<li className={styles.listItem}>
											<CheckCircleOutlined style={{ color: '#55a939' }} />
											<p>1,000 email credits / month</p>
										</li>
										<li className={styles.listItem}>
											<CheckCircleOutlined style={{ color: '#55a939' }} />
											<p>Technographics data</p>
										</li>
										<li className={styles.listItem}>
											<CheckCircleOutlined style={{ color: '#55a939' }} />
											<p>Buyers Intent insights</p>
										</li>
										<li className={styles.listItem}>
											<CheckCircleOutlined style={{ color: '#55a939' }} />
											<p>Unlimited AI search</p>
										</li>
										<li className={styles.listItem}>
											<CheckCircleOutlined style={{ color: '#55a939' }} />
											<p>Export to CSV / Pipedrive / lemlist</p>
										</li>
										<li className={styles.listItem}>
											<CheckCircleOutlined style={{ color: '#55a939' }} />
											<p>Access to advanced search filters</p>
										</li>
										<li className={styles.listItem}>
											<CheckCircleOutlined style={{ color: '#55a939' }} />
											<p>Email and chat support</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className={styles.priceItem}>
							<div className={styles.itemWrapper}>
								<div className={styles.itemContent}>
									<h3 className={styles.itemTitle}>Business</h3>
									<p className={styles.itemText}>10,000 email credits / month</p>
									<div className={styles.itemPrice}>$89 USD</div>
									<div className={styles.itemDescr}>Per user, per month</div>
									<a href="https://app.muraena.ai/login?package=business" className={styles.itemLink}>
										Start a free trial
									</a>
									<div className={styles.linkDescr}>No credit card required</div>
								</div>
								<div className={styles.itemFeatures}>
									<h4 className={styles.featuresTitle}>Key features:</h4>
									<ul className={styles.featuresList}>
										<li className={styles.listItem}>
											<CheckCircleOutlined style={{ color: '#55a939' }} />
											<p>10,000 email credits / month</p>
										</li>
										<li className={styles.listItem}>
											<CheckCircleOutlined style={{ color: '#55a939' }} />
											<p>Technographics data</p>
										</li>
										<li className={styles.listItem}>
											<CheckCircleOutlined style={{ color: '#55a939' }} />
											<p>Buyers Intent insights</p>
										</li>
										<li className={styles.listItem}>
											<CheckCircleOutlined style={{ color: '#55a939' }} />
											<p>Unlimited AI search</p>
										</li>
										<li className={styles.listItem}>
											<CheckCircleOutlined style={{ color: '#55a939' }} />
											<p>Export to CSV / Pipedrive / lemlist</p>
										</li>
										<li className={styles.listItem}>
											<CheckCircleOutlined style={{ color: '#55a939' }} />
											<p>Access to advanced search filters</p>
										</li>
										<li className={styles.listItem}>
											<CheckCircleOutlined style={{ color: '#55a939' }} />
											<p>Email and chat support</p>
										</li>
										<li className={styles.listItem}>
											<CheckCircleOutlined style={{ color: '#55a939' }} />
											<p>API access</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className={styles.faqSection}>
					<h1 className={styles.faqTitle}>Questions? We have answers.</h1>
					<Faq />
				</section>
			</div>
			<LandingFooter />
		</>
	);
};
