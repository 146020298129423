import { Text } from 'shared/components/ui';
import { ForgotPasswordForm } from 'features/auth';

import styles from './index.module.scss';

export const ForgotPassword = () => {
	return (
		<section className={styles.container}>
			<main className={styles.login}>
				<ForgotPasswordForm />
			</main>
		</section>
	);
};
