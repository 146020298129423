import { memo, useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import cn from 'classnames';

import { SearchCompany } from 'shared/generated-models';
import { ProfileCompany, ProfileEmail, ProfileName } from 'shared/components';
import { ContactModal } from 'features/candidate';
import { Icon, Text } from 'shared/components/ui';
import { TableSelectionMode, Td, Tr } from 'features/table';
import { CandidateProfileAvatar } from 'shared/components';

import styles from './index.module.scss';

interface Props {
	onClick: () => void;
	checkbox: {
		checked: boolean;
		onChange: () => void;
		mode: TableSelectionMode;
	};
	candidate: SearchCompany;
}

const ITEMS_TO_SHOW = 5;

const formatWebsite = (website: string) => {
	if (website.includes('http://')) {
		website = website.split('http://')[1];
	}
	if (website.includes('https://')) {
		website = website.split('https://')[1];
	}
	if (website.endsWith('/')) {
		website = website.slice(0, -1);
	}
	if (website.startsWith('www.')) {
		website = website.slice(4, website.length);
	}
	return website;
};

const formatLocation = (city?: string, country?: string) => {
	if (!city && !country) return '';
	if (!country) return city;
	if (!city) return country;
	return `${city}, ${country}`;
};

const companyInitials = (name?: string): string => {
	if (!name) return '';
	const words = name.trim().split(/\s+/);
	const initials = words.map(word => word.charAt(0).toUpperCase()).join('');
	return initials;
};

const extractDomain = (url?: string): string => {
	if (!url) return '';
	try {
		const { protocol, host } = new URL(url);
		return `${protocol}//${host}`;
	} catch (error) {
		console.error('Invalid URL:', url);
		return '';
	}
};

export const CompanyRow = memo(({ onClick, checkbox, candidate }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [logo, setLogo] = useState<string | null>(null);

	useEffect(() => {
		const fetchLogo = async () => {
			try {
				const logoUrl = `https://logo.clearbit.com/${extractDomain(candidate?.website)}`;
				const response = await fetch(logoUrl);
				if (response.ok) {
					setLogo(logoUrl);
				} else {
					setLogo(null);
				}
			} catch (error) {
				console.error('Failed to fetch logo:', error);
				setLogo(null);
			}
		};

		fetchLogo();
	}, [candidate]);

	const company = candidate;

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const openLink = (link: string) => () => {
		window.open(link, '_blank');
	};

	return (
		<>
			<Tr className={styles.profile} onClick={onClick}>
				<div className={styles.border}></div>
				<div className={styles.wrapper}>
					<Td width="48px">
						<Checkbox
							className={cn(styles.profileCheckbox, {
								[styles.profileCheckboxVisible]: checkbox.checked
							})}
							checked={checkbox.checked}
							onChange={checkbox.onChange}
						/>
					</Td>
					<Td className={styles.cell} width="20%">
						<div className={styles.profileNameWrapper}>
							{logo ? (
								<img className={styles.logo} src={logo} alt="logo" />
							) : (
								<CandidateProfileAvatar size={32}>
									{companyInitials(company.name)}
								</CandidateProfileAvatar>
							)}
							<Text className={styles.name} variant="grotesk/16/bold" component="p">
								{company.name}
							</Text>
						</div>
					</Td>
					<Td className={styles.cell} width="20%">
						<div className={styles.links}>
							{company.linkedin_url && (
								<Icon
									icon="linkedin-logo"
									className={styles.linksLinkedin}
									size={16}
									onClick={openLink(company.linkedin_url)}
								/>
							)}
							{company.linkedin_url && company.website && <div className={styles.linksDivider} />}
							{company.website && (
								<p className={styles.linksWebsite} onClick={openLink(company.website)}>
									{formatWebsite(company.website)}
								</p>
							)}
						</div>
					</Td>
					<Td className={styles.cell} width="20%">
						<Text className={styles.title} variant="inter/14/regular" component="p">
							{company.industries?.join(', ')}
						</Text>
					</Td>
					<Td className={styles.cell} width="16%">
						<Text className={styles.title} variant="inter/14/regular" color="another_grey">
							{formatLocation(company.city, company.country)}
						</Text>
					</Td>
					<Td className={styles.cell} width="16%">
						<Text variant="inter/14/regular">
							{company.company_size_min && company.company_size_max
								? `${company.company_size_min} - ${company.company_size_max}`
								: 'undefined'}
						</Text>
					</Td>
				</div>
			</Tr>
			{/* {isModalOpen && <ContactModal onClose={closeModal} id={profile.profile_id} variant="search" />} */}
		</>
	);
}, arePropsEqual);

function arePropsEqual(oldProps: Props, newProps: Props) {
	return oldProps.checkbox.checked === newProps.checkbox.checked && oldProps.checkbox.mode === newProps.checkbox.mode;
}
