import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';

import Check from 'shared/assets/svg-icons/check-gradient.svg';
import { Button, GradientBox, Icon, Text } from 'shared/components/ui';
import { Tabs } from 'shared/components/Tabs';
import { CustomPackage, IntervalEnum, Plan } from 'shared/generated-models';
import { currencyFormatter, MURAENA_EMAIL } from 'shared/utils';
import { BillingPlan } from 'shared/models';
import { useGetCurrentUserQuery, useGetCustomPackagesQuery, useGetPlansQuery } from 'services';
import { CardButton, CardSkeleton, CreditPurchaseButton } from 'features/subscription';

import styles from './index.module.scss';

function formatNumberWithCommas(number: number) {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const SubscriptionCards = () => {
	const location = useLocation();

	const { data, isFetching } = useGetPlansQuery();
	const { data: customPackages } = useGetCustomPackagesQuery();
	const { data: user } = useGetCurrentUserQuery();

	const [interval, setInterval] = useState(IntervalEnum.MONTH);
	const [selectedCustomPackage, setSelectedCustomPackage] = useState<CustomPackage | null>(null);

	useEffect(() => {
		if (customPackages) setSelectedCustomPackage(customPackages[0]);
	}, [customPackages]);

	useEffect(() => {
        if (location.state?.interval) {
            setInterval(location.state.interval);
        }
    }, [location.state]);

	const handleCustomPackage = (customPackage: CustomPackage) => () => {
		setSelectedCustomPackage(customPackage);
	};

	const [monthly] = useMemo(() => {
		if (data) {
			const monthly: Plan[] = [];
			// let trial: Plan | null = null;
			let free: Plan | null = null;

			data.forEach((item: Plan) => {
				if (item.interval === IntervalEnum.MONTH) {
					monthly.push(item);
				}
			});

			return [monthly];
		} else {
			return [[], [], null];
		}
	}, [data]);

	if (isFetching)
		return (
			<section className={styles.cards}>
				{[0, 1, 2].map(item => (
					<CardSkeleton key={item} />
				))}
			</section>
		);

	return (
		<div className={styles.cardsWrapper}>
			<div className={styles.tabsWrapper}>
				<Tabs
					value={interval}
					onChange={setInterval}
					items={[
						{ value: IntervalEnum.MONTH, label: 'Monthly Plans' },
						{ value: IntervalEnum.ONE_TIME, label: 'One-time purchase' }
					]}
				/>
			</div>
			{interval === IntervalEnum.MONTH && (
				<section className={styles.cards}>
					{monthly
						.sort((a, b) => a.credits - b.credits)
						.map(plan => (
							<GradientBox
								key={plan.id}
								className={cn(styles.cardGradient, {
									[styles.cardPro]: plan.billing_plan === BillingPlan.BUSINESS
								})}
								backgroundClassName={styles.cardGradientBefore}>
								<div className={styles.card}>
									<div className={styles.cardTop}>
										<Text variant="grotesk/20/regular" component="h3" className={styles.cardTitle}>
											{plan.billing_plan}
										</Text>
										<Text
											component="p"
											variant="grotesk/16/regular"
											color="grey"
											className={styles.cardPrice}>
											<Text variant="grotesk/32/bold">
												{currencyFormatter.format(plan.amount / 100)}
											</Text>
											<span>
												/ {plan.interval === IntervalEnum.MONTH ? ' monthly' : ' yearly'}
											</span>
										</Text>
									</div>
									<div className={styles.cardPerks}>
										<ul>
											<li>
												<img src={Check} alt="check icon" />
												<Text variant="grotesk/16/regular" component="p">
													<span>{plan.credits}</span> email credits / month
												</Text>
											</li>
											<li>
												<img src={Check} alt="check icon" />
												<Text variant="grotesk/16/regular" component="p">
													Technographics data
												</Text>
											</li>
											<li>
												<img src={Check} alt="check icon" />
												<Text variant="grotesk/16/regular" component="p">
													Buyers Intent insights
												</Text>
											</li>
											<li>
												<img src={Check} alt="check icon" />
												<Text variant="grotesk/16/regular" component="p">
													Unlimited AI search
												</Text>
											</li>
											<li>
												<img src={Check} alt="check icon" />
												<Text variant="grotesk/16/regular" component="p">
													Export to CSV / Pipedrive / lemlist
												</Text>
											</li>
											{plan.billing_plan === BillingPlan.BASIC && (
												<li>
													<img src={Check} alt="check icon" />
													<Text variant="grotesk/16/regular" component="p">
														Access to advanced search filters
													</Text>
												</li>
											)}
											{plan.billing_plan === BillingPlan.BASIC && (
												<li>
													<img src={Check} alt="check icon" />
													<Text variant="grotesk/16/regular" component="p">
														Email and chat support
													</Text>
												</li>
											)}
											{plan.billing_plan === BillingPlan.BUSINESS && (
												<li>
													<img src={Check} alt="check icon" />
													<Text variant="grotesk/16/regular" component="p">
														Access to advanced search filters
													</Text>
												</li>
											)}
											{plan.billing_plan === BillingPlan.BUSINESS && (
												<li>
													<img src={Check} alt="check icon" />
													<Text variant="grotesk/16/regular" component="p">
														Email and chat support
													</Text>
												</li>
											)}
											{plan.billing_plan === BillingPlan.BUSINESS && (
												<li>
													<img src={Check} alt="check icon" />
													<Text variant="grotesk/16/regular" component="p">
														API access
													</Text>
												</li>
											)}
										</ul>
									</div>

									<CardButton
										className={
											plan.billing_plan !== BillingPlan.BUSINESS ? styles.bottomButton : ''
										}
										billingPlan={plan.billing_plan}
										priceId={plan.id}
									/>
								</div>
							</GradientBox>
						))}
				</section>
			)}
			{interval === IntervalEnum.ONE_TIME && customPackages && selectedCustomPackage && (
				<section className={styles.oneTime}>
					<div className={styles.oneTimePrices}>
						{customPackages.map((customPackage: CustomPackage) => (
							<Button type="default" onClick={handleCustomPackage(customPackage)}>
								{customPackage.name} <Text>for</Text> $
								{formatNumberWithCommas(customPackage.amount / 100)}
							</Button>
						))}
					</div>
					<GradientBox
						key={monthly[0].id}
						className={styles.cardGradient}
						backgroundClassName={styles.cardGradientBefore}>
						<div className={styles.oneTimeCard}>
							<div className={styles.oneTimeCardTopRow}>
								<div className={styles.oneTimeCardTop}>
									<Text
										variant="grotesk/20/regular"
										component="h3"
										className={styles.oneTimeCardTitle}>
										Credits amount
									</Text>
									<Text
										component="p"
										variant="grotesk/16/regular"
										color="grey"
										className={styles.cardPrice}>
										<Text variant="grotesk/32/bold">{selectedCustomPackage.credits}</Text>
									</Text>
								</div>
								<div className={styles.oneTimeCardTop}>
									<Text
										variant="grotesk/20/regular"
										component="h3"
										className={styles.oneTimeCardTitle}>
										Total Price
									</Text>
									<Text
										component="p"
										variant="grotesk/16/regular"
										color="grey"
										className={styles.cardPrice}>
										<Text variant="grotesk/32/bold">
											{currencyFormatter.format(selectedCustomPackage.amount / 100)}
										</Text>
									</Text>
								</div>
							</div>
							<div className={styles.oneTimeDescription}>
								<Text variant="grotesk/15/regular">
									You're about to proceed with one-time purchase of the email/export credits.
								</Text>
								<Text variant="grotesk/15/regular">
									They will be added on top of your current pricing plan and you can use them anytime
									- they don't have an expiration date.
								</Text>
							</div>
							<CreditPurchaseButton priceId={selectedCustomPackage.id} />
						</div>
					</GradientBox>
				</section>
			)}
		</div>
	);
};
