import { SignUpForm } from 'features/auth';

import styles from './index.module.scss';


export const SignUp = () => {
	return (
		<section className={styles.container}>
			<main className={styles.login}>
				<SignUpForm />
			</main>
		</section>
	);
};
