import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form } from 'antd';

import { Button, FormikInput, Text } from 'shared/components/ui';
import { useCreatePasswordMutation } from 'services';
import { LoginEditForm } from 'shared/models';
import { loginFormValidationSchema } from 'features/registration';

import styles from './index.module.scss';

export const SignUpExistingForm = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const email = searchParams.get('email');

	const [createPassword] = useCreatePasswordMutation();

	const handleSubmit = async (values: LoginEditForm) => {
		await createPassword({ email: values.email!, action: 'create' });
		navigate(`/check-email?email=${values.email}`);
	};

	const formik = useFormik<LoginEditForm>({
		validationSchema: loginFormValidationSchema,
		enableReinitialize: true,
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			email: email || ''
		},
		onSubmit: values => handleSubmit(values)
	});

	const handleContinueWithEmail = () => {
		formik.handleSubmit();
	};

	const handleCancel = () => {
		navigate('/login');
	};

	return (
		<div className={styles.login}>
			<Text variant="inter/26/semi" className={styles.loginHeader}>
				This email is already linked to your Google account.
			</Text>
			<Text variant="inter/16/medium" className={styles.loginSubheader}>
				Would you like to create a password for email login?
			</Text>

			<FormikProvider value={formik}>
				<Form className={styles.form} layout="vertical" requiredMark={false} name="registration">
					<FormikInput className={styles.input} name="email" label="Email" size="large" placeholder="Email" />
					<Button
						className={styles.loginButton}
						type="primary"
						block
						onClick={handleContinueWithEmail}
						disabled={!formik.isValid}>
						Create Password
					</Button>
				</Form>
			</FormikProvider>
			<Button className={styles.loginSecondary} type="default" block onClick={handleCancel}>
				Cancel
			</Button>
		</div>
	);
};
