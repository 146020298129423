import { FC } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { Button, Icon, Text } from 'shared/components/ui';
import { SearchCompany } from 'shared/generated-models';

import styles from './index.module.scss';

interface Props {
	data?: SearchCompany;
	candidates: SearchCompany[];
	page: number;
	onClose: () => void;
	total?: number;
	setCandidate: (candidate: SearchCompany | null) => void;
	onChange: (page: number) => void;
}

export const CompanyHeader: FC<Props> = ({ data, onClose, candidates, page, total, setCandidate, onChange }: Props) => {
	const idx = (() => {
		if (candidates) return candidates.findIndex(candidate => candidate.id === data!.id);
	})();
	const isFirstPage = page === 1;
	const isFirstCandidate = idx === 0;

	const isLastPage = (() => {
		if (!!total) return total <= (page + 1) * 20;
		if (candidates) return (page + 1) * 20 > page * 20 + candidates.length;
	})();
	const isLastCandidate = (() => {
		if (candidates) return idx === candidates.length - 1;
	})();

	const handlePrevCandidateClick = () => {
		if (isFirstCandidate) return;
		if (candidates && setCandidate && idx !== undefined) setCandidate(candidates[idx - 1]);
	};

	const handleNextCandidateClick = () => {
		if (isLastCandidate) return;
		if (candidates && setCandidate && idx !== undefined) setCandidate(candidates[idx + 1]);
	};

	const handlePrevPage = () => {
		onChange(page - 1);
		if (setCandidate) setCandidate(null);
	};

	const handleNextPage = () => {
		onChange(page + 1);
		if (setCandidate) setCandidate(null);
	};

	return (
		<>
			<header className={styles.header}>
				<Button onClick={onClose} type="ghost" className={cn(styles.button, styles.back)}>
					<Icon icon="chevron" size={20} />
				</Button>
				{data && data.website && (
					<div className={styles.headerSection}>
						<Button
							onClick={isFirstCandidate ? handlePrevPage : handlePrevCandidateClick}
							type="ghost"
							className={cn(styles.button, {
								[styles.buttonDisabled]: isFirstCandidate && isFirstPage
							})}
							prefix={<Icon icon="chevron" size={20} className={cn(styles.icon, styles.iconPrevious)} />}>
							<Text variant="inter/14/regular" color="grey">
								Previous
							</Text>
						</Button>
						<Button
							onClick={isLastCandidate ? handleNextPage : handleNextCandidateClick}
							type="ghost"
							className={cn(styles.button, {
								[styles.buttonDisabled]: isLastCandidate && isLastPage
							})}
							prefix={<Icon icon="chevron" size={20} className={cn(styles.icon)} />}>
							<Text variant="inter/14/regular" color="grey">
								Next
							</Text>
						</Button>
						<Link
							to={`/companies_search/results?page=0&lookalike[]=${data.website}&size=100`}
							target="_blank"
							rel="noopener,nofollow,noreferrer">
							<Button
								type="ghost"
								className={styles.button}
								prefix={<Icon icon="search-candidate" className={styles.icon} />}>
								<Text variant="inter/13/regular" color="grey">
									Similar companies
								</Text>
							</Button>
						</Link>
					</div>
				)}
				<Link
					to={`/search/results?page=0&companies[]=${data?.name}&size=100`}
					target="_blank"
					rel="noopener,nofollow,noreferrer"
					className={styles.buttonEmployees}>
					<Button
						type="default"
						className={styles.buttonReveal}
						prefix={<Icon icon="email" className={styles.icon} />}>
						<Text variant="inter/15/semi">Reveal employees contacts</Text>
					</Button>
				</Link>
			</header>
		</>
	);
};
