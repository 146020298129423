import * as yup from 'yup';

import { CreatePasswordForm } from 'shared/models';

export const createPasswordFormValidationSchema: yup.SchemaOf<CreatePasswordForm> = yup.object().shape({
	password: yup
		.string()
		.required('Password is required')
		.min(8, 'Password must be at least 8 characters')
		.max(25, 'Password must be at most 25 characters'),
    confirm_password: yup
		.string()
		.required('Password is required')
		.min(8, 'Password must be at least 8 characters')
		.max(25, 'Password must be at most 25 characters'),
});
