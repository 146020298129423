import { FC, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form } from 'antd';

import { Button, FormikInput, FormikInputPassword, Text } from 'shared/components/ui';
import { useAppDispatch } from 'store';
import { setCredentials } from 'store/credentials';
import { useConfirmCreatePasswordMutation } from 'services';
import { parseJwt } from 'shared/utils';
import { Analytics } from 'features/analytics';
import { CreatePasswordForm as CreatePasswordEditForm } from 'shared/models';
import { createPasswordFormValidationSchema } from 'features/registration';

import styles from './index.module.scss';

interface Props {
	reset?: boolean;
}

export const CreatePasswordForm = ({ reset }: Props) => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [error, setError] = useState<string | null>(null);

	const uid = searchParams.get('uid');
	const token = searchParams.get('token');

	const [confirmCreatePassword] = useConfirmCreatePasswordMutation();

	const handleSubmit = async (values: CreatePasswordEditForm) => {
		if (values.password !== values.confirm_password) {
			setError('Passwords do not match!');
			return;
		}

		const response = await confirmCreatePassword({
			uid: uid!,
			token: token!,
			password: values.password,
			confirm_password: values.confirm_password
		});

		if (reset) {
			navigate('/password_changed');
			return;
		}
		navigate('/sign-in');
	};

	const formik = useFormik<CreatePasswordEditForm>({
		validationSchema: createPasswordFormValidationSchema,
		enableReinitialize: true,
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			password: '',
			confirm_password: ''
		},
		onSubmit: values => handleSubmit(values)
	});

	const handleContinue = () => {
		setError(null);
		formik.handleSubmit();
	};

	return (
		<div className={styles.login}>
			<Text variant="inter/32/semi" className={styles.loginHeader}>
				{reset ? 'Change Your Password' : 'Create a password'}
			</Text>
			<FormikProvider value={formik}>
				<Form className={styles.form} layout="vertical" requiredMark={false} name="registration">
					<FormikInputPassword
						className={styles.input}
						name="password"
						label="Password"
						size="large"
						placeholder={reset ? 'New password' : 'Password'}
					/>
					<FormikInputPassword
						className={styles.input}
						name="confirm_password"
						label="Confirm password"
						size="large"
						placeholder={reset ? 'Confirm new password' : 'Confirm password'}
					/>
					{error && (
						<div style={{ marginBottom: '16px', textAlign: 'left' }}>
							<Text variant="inter/14/regular" color="red">
								{error}
							</Text>
						</div>
					)}
					<Button
						className={styles.loginButton}
						type="primary"
						block
						onClick={handleContinue}
						disabled={!formik.isValid}>
						{reset ? 'Reset password' : 'Continue'}
					</Button>
					{!reset && (
						<>
							<Text variant="inter/16/regular" color="another_grey">
								Already have an account?{' '}
								<button
									onClick={() => {
										navigate('/sign-in');
									}}
									className={styles.link}>
									<span>Sign in</span>
								</button>
							</Text>
							<Text variant="inter/16/regular">
								<button
									onClick={() => {
										navigate('/sign-in');
									}}
									className={styles.link}>
									<span>Use a different email</span>
								</button>
							</Text>
						</>
					)}
				</Form>
			</FormikProvider>
		</div>
	);
};
