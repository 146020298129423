import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';

import {
	useLazyGetNewCsvQuery,
	useLazyGetCsvAsyncQuery,
	useLazyGetCompaniesCsvAsyncQuery,
	useLazyGetPipedriveServiceQuery,
	useLazyGetHubspotServiceQuery
} from 'services';
import { Pagination, useTableSelection, TableSelection, Th, Tr, Table } from 'features/table';
import { Button, Checkbox, Icon, Text, openNotification } from 'shared/components/ui';
import { useFilters, useGetCompanies, useCompanySearch } from 'features/search/hooks';
import { SearchCompany, ProgAIResults } from 'shared/generated-models';
import { ResultsEmpty, ResultsError, ResultsSkeleton, SearchUpdate, CompanyRow } from 'features/search/components';
import { BulkContactsModal, CompanyModal } from 'features/candidate';
import { ExportCsvCompanyButton } from 'features/csv-export';
import { Dropdown, Menu } from 'antd';
import { ExportPipeButton } from 'features/csv-export/components/ExportPipeButton';
import { ExportHubspotButton } from 'features/csv-export/components/ExportHubspotButton';
import { useGetCurrentUserQuery } from 'services';
import { IntergationsPushMenu } from 'features/integrations';
import { SuccessModal } from 'features/csv-export';
import { useSaveHistoryMutation } from 'services';
import { isDeepEqual } from 'shared/utils';
import { useFileContext } from 'features/auth';
import { setSearchType, useAppDispatch } from 'store';

import styles from './index.module.scss';

function formatNumberWithCommas(number: number) {
	if (number > 300000) return '300,000+';
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const CompanyResultsList = () => {
	const [candidate, setCandidate] = useState<SearchCompany | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isCsvExportSuccessModalOpen, setIsCsvExportSuccessModalOpen] = useState(false);
	const [isPipeModalOpen, setIsPipeModalOpen] = useState(false);
	const [isHubspotModalOpen, setIsHubspotModalOpen] = useState(false);
	const [linkPipe, setLinkPipe] = useState(undefined);
	const [linkHubspot, setLinkHubspot] = useState(undefined);
	const [saveHistory] = useSaveHistoryMutation();
	const { contextValue, setContextValue } = useFileContext();
	const dispatch = useAppDispatch();

	const { data: user, refetch: refetchUser } = useGetCurrentUserQuery();

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const closeCsvExportSuccessModal = () => {
		setIsCsvExportSuccessModalOpen(false);
	};

	const openPipeModal = () => {
		setIsPipeModalOpen(true);
	};

	const closePipeModal = () => {
		setIsPipeModalOpen(false);
		setLinkPipe(undefined);
	};

	const openHubspotModal = () => {
		setIsHubspotModalOpen(true);
	};

	const closeHubspotModal = () => {
		setIsHubspotModalOpen(false);
		setLinkHubspot(undefined);
	};

	const location = useLocation();
	const navigate = useNavigate();

	const companySearch = useCompanySearch();
	const filters = useFilters();
	const modifiedFilters = { ...filters };
	if (!!contextValue) {
		// @ts-ignore
		if ('company_domains' in modifiedFilters && modifiedFilters['company_domains'].length > 0) {
			const updatedDomains = Array.from(
				// @ts-ignore
				new Set([...modifiedFilters['company_domains'], ...contextValue.domains])
			);
			modifiedFilters['company_domains'] = updatedDomains;
		} else {
			// @ts-ignore
			modifiedFilters['company_domains'] = Array.from(new Set(contextValue.domains));
		}
	}
	if ('headcount' in modifiedFilters) {
		const prevHeadcount = modifiedFilters['headcount'];
		const newHeadcount: any = [];
		// @ts-ignore
		prevHeadcount.forEach(item => {
			if (item === '10000+') {
				return newHeadcount.push([10000, null]);
			}
			return newHeadcount.push(item.split('-').map((str: string) => parseInt(str)));
		});
		modifiedFilters['headcount'] = newHeadcount;
	}

	if ('total_funding_start' in modifiedFilters || 'total_funding_end' in modifiedFilters) {
		// @ts-ignore
		modifiedFilters['total_funding_amount'] = [
			// @ts-ignore
			modifiedFilters['total_funding_start'] || null,
			// @ts-ignore
			modifiedFilters['total_funding_end'] || null
		];
		if ('total_funding_start' in modifiedFilters) delete modifiedFilters['total_funding_start'];
		if ('total_funding_end' in modifiedFilters) delete modifiedFilters['total_funding_end'];
	}

	if ('cities' in modifiedFilters) {
		if ('countries' in modifiedFilters) delete modifiedFilters['countries'];
		if ('subregions' in modifiedFilters) delete modifiedFilters['subregions'];
		if ('regions' in modifiedFilters) delete modifiedFilters['regions'];
	}
	if ('countries' in modifiedFilters) {
		if ('subregions' in modifiedFilters) delete modifiedFilters['subregions'];
		if ('regions' in modifiedFilters) delete modifiedFilters['regions'];
	}
	if ('subregions' in modifiedFilters) {
		if ('regions' in modifiedFilters) delete modifiedFilters['regions'];
	}
	if ('company_domain_rank' in modifiedFilters) {
		const range: any = modifiedFilters['company_domain_rank'];
		modifiedFilters['company_domain_rank'] = [parseInt(range[0]), parseInt(range[1])];
	}
	if ('company_founded_year' in modifiedFilters) {
		const range: any = modifiedFilters['company_founded_year'];
		modifiedFilters['company_founded_year'] = [parseInt(range[0]), parseInt(range[1])];
	}

	const ref = useRef<HTMLDivElement>(null);

	const [getCsvAsync] = useLazyGetCompaniesCsvAsyncQuery();
	const [getPipedriveData] = useLazyGetPipedriveServiceQuery();
	const [hasPipedriveError, setHasPipedriveError] = useState(false);
	const [getHubspotData] = useLazyGetHubspotServiceQuery();
	const [hasHubspotError, setHasHubspotError] = useState(false);
	const [isHistorySaved, setIsHistorySaved] = useState(false);
	const prevModifiedFiltersRef = useRef(modifiedFilters);

	const closeCandidateModal = () => {
		setCandidate(null);
	};

	useEffect(() => {
		ref.current?.scroll({ top: 0, behavior: 'smooth' });
	}, [filters.page]);

	const { data, rawData, isFetching, isError, refetch, totalLoaded } = useGetCompanies();

	useEffect(() => {
		if (isFetching) return;
		const prevModifiedFilters = prevModifiedFiltersRef.current;
		if (data && data.count && (!isHistorySaved || !isDeepEqual(prevModifiedFilters, modifiedFilters))) {
			if (modifiedFilters.page === 1) {
				// only this line:
				saveHistory({
					filters: modifiedFilters,
					leads_number: data.count,
					content_type: 'companies'
				});
				// refetch();
			}
			setIsHistorySaved(true);
		}
		prevModifiedFiltersRef.current = modifiedFilters;
	}, [data, isHistorySaved, modifiedFilters]);

	const {
		selectAll,
		isAllSelected,
		toggle,
		isSelected,
		someSelected,
		count,
		selectPage,
		selectCount,
		included,
		excluded,
		togglePage,
		clearSelection,
		selectedCount,
		mode
	} = useTableSelection<SearchCompany>({
		data: data?.results.map(row => row),
		totalCount: data?.count,
		max: data?.count
	});

	useEffect(() => {
		if (location.state && location.state.refresh) {
			clearSelection();
		}
	}, [location.key]);

	const downloadCsv = (response: any) => {
		if (response.error) {
			const message = JSON.parse(response.error.data).detail;
			openNotification({ text: message, closable: true });
			setLoading(false);
			closeModal();
			return;
		}

		setLoading(false);
		closeModal();
		setIsCsvExportSuccessModalOpen(true);
		refetch();
		refetchUser();
	};

	const [countValue, setCountValue] = useState(0);

	const defaultTogglePage = () => {
		if (countValue || included.length || selectedCount) return;
		if (filters.page !== 1) {
			togglePage();
			return;
		}

		let count = 100;
		if (data?.count && data?.count < count) count = data?.count;
		if (user && user.credits < count) count = user.credits;
		selectCount(count);
		setCountValue(count);
	};

	const handleExportButtonClick = () => {
		defaultTogglePage();
	};

	const handleShowEmployees = () => {
		if (selectedCount > 100) return;
		if (included.length > 100) return;

		if (!!selectedCount) {
			const companyNames = rawData!.results
				.slice(0, selectedCount)
				.filter(item => !excluded.includes(item.id))
				.map(item => encodeURIComponent(item.name!));
			dispatch(setSearchType('people'));
			navigate(`/search/results?page=0&companies[]=${companyNames.join(',')}&size=100`);
			return;
		}

		if (!!included.length) {
			const companyNames = rawData!.results
				.filter(item => included.includes(item.id))
				.map(item => encodeURIComponent(item.name!));
			dispatch(setSearchType('people'));
			navigate(`/search/results?page=0&companies[]=${companyNames.join(',')}&size=100`);
			return;
		}

		dispatch(setSearchType('people'));

		const params = qs.parse(location.search, {
			arrayFormat: 'bracket-separator',
			arrayFormatSeparator: ','
		});
		const COMPANY_SPECIFIC_FILTERS = ['company_cities', 'linkedin_url'];
		COMPANY_SPECIFIC_FILTERS.forEach(filter => {
			if (filter in params) delete params[filter];
		});
		const url = qs.stringify(params, {
			arrayFormat: 'bracket-separator',
			arrayFormatSeparator: ','
		});
		navigate(`/search/results${`?${url}`}`);
	};

	useEffect(() => {
		dispatch(setSearchType('companies'));
	}, []);

	const handleExport = (isFull: boolean, onlyWorkEmails: boolean) => {
		setLoading(true);
		if (included.length > 0) {
			getCsvAsync({
				// filters: { ...modifiedFilters, work_email_only: onlyWorkEmails, id: included },
				filters: { ...modifiedFilters, id: included }
				// profile_id_list: included,
				// all_fields: isFull
			}).then(downloadCsv);
		} else if (selectedCount > 0) {
			getCsvAsync({
				// filters: { ...modifiedFilters, work_email_only: onlyWorkEmails, negative_id: excluded },
				filters: { ...modifiedFilters, negative_id: excluded },
				limit: selectedCount - excluded.length || undefined
				// exclude_ids: excluded,
				// all_fields: isFull
			}).then(downloadCsv);
		}
	};

	const handlePipedriveData = async () => {
		if (included.length > 3000 || selectedCount > 3000) {
			openNotification({ text: 'You can export up to 3000 leads', closable: true });
			return;
		}

		try {
			setLoading(true);
			setHasPipedriveError(false);
			let data;
			if (included.length > 0) {
				data = await getPipedriveData({ profile_id_list: included, all_fields: true });
			} else {
				data = await getPipedriveData({
					filters: modifiedFilters,
					limit: selectedCount || undefined,
					exclude_ids: excluded,
					all_fields: true
				});
			}
			setLinkPipe(data.data.redirect_url);
			setLoading(false);
		} catch (error) {
			console.error('error:', error);
			setLoading(false);
			throw error;
		}
	};

	const handleHubspotData = async () => {
		if (included.length > 3000 || selectedCount > 3000) {
			openNotification({ text: 'You can export up to 3000 leads', closable: true });
			return;
		}

		try {
			setLoading(true);
			setHasHubspotError(false);
			let data;
			if (included.length > 0) {
				data = await getHubspotData({ profile_id_list: included, all_fields: true });
			} else {
				data = await getHubspotData({
					filters: modifiedFilters,
					limit: selectedCount || undefined,
					exclude_ids: excluded,
					all_fields: true
				});
			}
			setLinkHubspot(data.data.redirect_url);
			setLoading(false);
		} catch (error) {
			console.error('error:', error);
			setLoading(false);
			throw error;
		}
	};

	if (isFetching) return <ResultsSkeleton />;

	if (isError || !data) return <ResultsError />;

	if (data.results.length === 0) return <ResultsEmpty />;

	const menu = (
		<Menu>
			<Menu.Item key="1">
				<ExportCsvCompanyButton
					type="ghost"
					handleExport={handleExport}
					disabled={!someSelected}
					candidates={{ count, profile_id_list: included, limit: selectedCount || undefined }}
					loading={loading}
					isModalOpen={isModalOpen}
					openModal={openModal}
					closeModal={closeModal}
				/>
			</Menu.Item>
			{/* <Menu.Item key="2">
				<ExportPipeButton
					type="ghost"
					handlePipedriveData={handlePipedriveData}
					// disabled={!someSelected}
					disabled={true}
					candidates={{ count, profile_id_list: included, limit: selectedCount || undefined }}
					loading={loading}
					isPipeModalOpen={isPipeModalOpen}
					openPipeModal={openPipeModal}
					closePipeModal={closePipeModal}
					linkPipe={linkPipe}
				/>
			</Menu.Item> */}
			{/* <Menu.Item key="3">
				<ExportHubspotButton
					type="ghost"
					handleHubspotData={handleHubspotData}
					// disabled={!someSelected}
					disabled={true}
					candidates={{ count, profile_id_list: included, limit: selectedCount || undefined }}
					loading={loading}
					isHubspotModalOpen={isHubspotModalOpen}
					openHubspotModal={openHubspotModal}
					closeHubspotModal={closeHubspotModal}
					linkHubspot={linkHubspot}
				/>
			</Menu.Item> */}
			{/* <Menu.Item key="4">
				<IntergationsPushMenu
					type="ghost"
					candidates={{
						count,
						profile_id_list: included,
						limit: selectedCount,
						exclude_ids: excluded,
						filters: modifiedFilters || undefined
					}}
					// disabled={!someSelected}
					disabled={true}
				/>
			</Menu.Item> */}
		</Menu>
	);

	return (
		<section className={styles.container}>
			<div className={styles.info}>
				<Text variant="grotesk/14/bold">
					{data.count
						? `${formatNumberWithCommas(data.count)} Companies`
						: `More than ${formatNumberWithCommas(totalLoaded)} Companies`}
				</Text>
				<div className={styles.listControlInline}>
					<Pagination
						page={filters.page}
						onChange={p => companySearch({ ...filters, page: p })}
						onPageCount={data.results.length}
						total={data.count}
					/>
				</div>
			</div>
			<Table className={styles.list} ref={ref}>
				<div className={styles.header}>
					<div className={styles.listControl}>
						<TableSelection
							controls={{
								main: { togglePage, count, isAllSelected },
								extra: { selectPage, selectAll, selectCount }
							}}
							hint="Select leads"
							total={data?.count}
							countValue={countValue}
							setCountValue={setCountValue}
						/>
						<div className={styles.infoButtons}>
							<div className={styles.infoButtons}>
								<Button
									type="default"
									onClick={handleShowEmployees}
									disabled={selectedCount > 100 || included.length > 100}>
									Show employees
								</Button>
								<div>
									<Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
										<Button
											type="primary"
											onClick={handleExportButtonClick}
											className={styles.project}
											suffix={<Icon icon="arrow-down" />}>
											Export
										</Button>
									</Dropdown>
								</div>
							</div>
						</div>
					</div>
					<Tr className={styles.tableHeader}>
						<Th width="48px" />
						<Th width="20%" />
						<Th width="20%">Links</Th>
						<Th width="20%">Industry</Th>
						<Th width="16%">Location</Th>
						<Th width="16%">Headcount</Th>
						{/* <Th className={styles.tableColumn} /> */}
					</Tr>
				</div>
				{data.results.map((candidate, idx) => (
					<CompanyRow
						checkbox={{
							checked: isSelected({ row: candidate, page: filters.page - 1, idx }),
							onChange: () => toggle({ row: candidate, page: filters.page - 1, idx }),
							mode
						}}
						key={candidate.id}
						onClick={() => setCandidate(candidate)}
						candidate={candidate}
					/>
				))}
			</Table>
			{candidate && (
				<CompanyModal
					candidate={candidate}
					candidates={data.results}
					onClose={closeCandidateModal}
					page={filters.page}
					setCandidate={setCandidate}
					onChange={p => companySearch({ ...filters, page: p })}
					total={data.count}
				/>
			)}
			{isCsvExportSuccessModalOpen && <SuccessModal handleClose={closeCsvExportSuccessModal} />}
		</section>
	);
};
